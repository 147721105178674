import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

//icon
 
import logo from "../assets/images/logo.png";
import IMG01 from "../assets/images/doctors/doctor-thumb-02.jpg";
import Dropdown from "react-bootstrap/Dropdown";
 
import { useEffect } from "react";

import {getCookie, decodeToken } from './functions.js';

const Header = (props) => {
  let pathnames = window.location.pathname

  const [rol, setRol] = useState("");
  const [show, setShow] = useState(false);
  const [useractive, setUseractive] = useState("");
  const url = pathnames.split("/").slice(0, -1).join("/");

  const onHandleMobileMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };

  useEffect(() => {
     
    
    const fetchFn2 = async ()=>{
    	var user = getCookie(	"nombreUsuarioINNOVA")

      let token= getCookie("tokenINNOVA");
      var _resp = await decodeToken (token)
 
      if (_resp.resp==="OK"){
        setRol(_resp.datos.rol)
      }		
    	if (user){
    		setUseractive(user)
    	}
    	
    }
 		fetchFn2();	
    
   // console.log("llave", process.env.REACT_APP_API_CONFIG)
    
  }, []);

  //console.log("s "+url+" "+pathnames)
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg header-nav">
        <div className="navbar-header">
          <a href="#0" id="mobile_btn" onClick={() => onHandleMobileMenu()}>
            <span className="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <Link to="/home" className="navbar-brand logo">
            <img src={logo} className="img-fluid" alt="Logo" />
          </Link>
        </div>
        <div className="main-menu-wrapper">
          <div className="menu-header">
            <Link to="/home" className="menu-logo">
              <img src={logo} className="img-fluid" alt="Logo" />
            </Link>
            <a
              href="#0"
              id="menu_close"
              className="menu-close"
              onClick={() => onhandleCloseMenu()}
            >
              <i className="fas fa-times"></i>
            </a>
          </div>
          <ul className="main-nav">
          	
          	
            <li className={pathnames.includes("/home") || pathnames=="/" ? "active" : ""}><Link to="/home">Inicio</Link></li>
            {rol==="Consultorio" && 
              <li className={pathnames.includes("/reservation")  ? "active" : ""}><Link to="/reservation">Registrar Consulta</Link></li>
            }

            {rol==="Monitor" && 
              <li className={pathnames.includes("/reservation")  ? "active" : ""}><Link to="/reservation">Mostrar Monitor</Link></li>
            }



            {rol==="Doctor" &&
              <li className={pathnames.includes("/doctor-dashboard")  ? "active" : ""}><Link to="/doctor-dashboard">Mis Pacientes</Link></li>
            }

            {rol!=="Consultorio" && rol!=="Doctor" &&  rol!=="Monitor" &&
              <li className={pathnames.includes("/HomeEcom") ? "active" : ""}>
                <Link to="/HomeEcom">
                  {useractive==""|| useractive=="null" || useractive==null? "Comprar": "Vender"}
              
                </Link>
              </li>
            }
            {useractive==""|| useractive=="null" || useractive==null? 
            	<li className={pathnames.includes("/login") ? "active" : ""}><Link to="/login-innova">Login</Link></li>
	             
	            :	
	            <li className={`has-submenu ${url.includes("/doctor") ? "active" : ""}`}>
	            <a onClick={()=> { setShow(!show) }} style={{textDecoration:"underline"}} href="#0">
	                {useractive}
	                <i className="fas fa-chevron-down" aria-hidden="true"></i>
	              </a>
	              <ul style={{ display: show?"block": "none"}}  className={`submenu`}>
                  {rol==="Administrador" &&
                    <li className={pathnames.includes("admin/dashboard") ? "active" : ""}>
                      <Link to="/manageusers" onClick={()=>onhandleCloseMenu()}>Usuarios</Link>
                    </li>
                  }
                  {rol!=="Consultorio" &&  rol!=="Doctor" &&  rol!=="Monitor" &&
                    <li className={pathnames.includes("admin/dashboard") ? "active" : ""}>
                      <Link to="/salesDashboard" onClick={()=>onhandleCloseMenu()}>Reportes</Link>
                    </li>
                  }
	                <li className={pathnames.includes("patient/dashboard") ? "active" : ""}>
	                  <Link to="/logout" onClick={()=>onhandleCloseMenu()}>Cerrar sesi&oacute;n</Link>
	                </li>
	              </ul>
	            </li>
	          }
              
            <li className="login-link" onClick={()=>onhandleCloseMenu()}>
              <Link to="/login">Agendar Citas</Link>
            </li>
          </ul>
        </div>
        <ul className="nav header-navbar-rht">
          
          {(props.location.pathname) === ("/pages/voice-call") || (props.location.pathname) === ("/pages/video-call") ? (
            <>
              <Dropdown className="user-drop nav-item dropdown has-arrow logged-item">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img
                    className="rounded-circle"
                    src={IMG01}
                    width="31"
                    alt="Darren Elder"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="user-header">
                    <div className="avatar avatar-sm">
                      <img
                        src={IMG01}
                        alt="User"
                        className="avatar-img rounded-circle"
                      />
                    </div>
                    <div className="user-text">
                      <h6>Darren Elder</h6>
                      <p className="text-muted mb-0">Doctor</p>
                    </div>
                  </div>
                  <Dropdown.Item href="/doctor/doctor-dashboard">
                    Dashboard
                  </Dropdown.Item>
                  <Dropdown.Item href="/doctor/profile-setting">
                    Profile Settings
                  </Dropdown.Item>
                  <Dropdown.Item href="/Logout">Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
              <li className="nav-item">
                <Link to="/login" className="nav-link header-login">
                  Agendar Citas{" "}
                </Link>
              </li>{" "}
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
