import React, { Component } from 'react';
import { Doctor01,Doctor02,Doctor03,Doctor04,Specialities01,Specialities02,
    Specialities03,Specialities04 } from "./img";
import { Link } from 'react-router-dom';    
import axios from 'axios';
import {API_CONFIG} from '../../../Config/config-env.js';
import {getMensajes}  from '../functions.js';
class HomeEcom extends Component{
	
	constructor(props) {
		super(props);
	 
		 
		this.state = {
			collapseID: "collapse1",
			 
		};
	}
	
	componentDidMount=async()=>{
		this.setState({   isExecuting:true });
		var respMensajes = await getMensajes()
		 this.setState({ ...this.state, 
                    direccion    :respMensajes.direccion,
										ubicacion    :respMensajes.ubicacion,
										isExecuting:false
                });
	}
 	
    render(){
        return(            
        <div className="main-wrapper">
             {/* Home Banner */}
         
      
        {/* Planes */}
        <section className="specialities">
          <div className="container">
            <div className="section-title text-center">
              <h2>Elija una Opcion</h2>
             
            </div>
            <div className="row justify-content-center">
              {/*
              <div className="col-12 col-md-6 col-lg-6">
                <div className="specialities-col">
                  <div className="card">
                  	<Link to="/HomeServices" className="booking-doc-img ">
											<button  type="button" className="btn btn-primary btn-block" style={{"minWidth": "80px",  "padding":"2rem" }} ><h2 className="text-white"> Atenciones</h2></button>
										</Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="specialities-col">
                  <div className="card">
	                  <Link to="/HomePlans" className="booking-doc-img ">
											<button  type="button" className="btn btn-primary btn-block" style={{"minWidth": "80px",  "padding":"2rem" }} ><h2 className="text-white"> Planes </h2></button>
										</Link>
                  </div>
                </div>
              </div>
              */}

              <div className="col-12 col-md-12 col-lg-12">
                <div className="specialities-col">
                  <div className="card">
	                  <Link to="/HomePlans" className="booking-doc-img ">
											<button  type="button" className="btn btn-primary btn-block" style={{"minWidth": "80px",  "padding":"2rem" }} ><h2 className="text-white"> Planes </h2></button>
										</Link>
                  </div>
                </div>
              </div>
               
            </div>
          </div>
        </section>
        {/* /Specialities */}	
       
         
       
        {/* /News Letter */}
        {/* Address */}
        <section className="address-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <address className="m-0 d-flex align-items-center">
                  <span className="d-flex align-items-center justify-content-center map-icon">
                   <a target="_blank" href={this.state.ubicacion}>
                   	<i className="fas fa-map-marker-alt" />
                   </a>	
                  </span>
                  <span>
                    {this.state.direccion} <br /> La Paz Bolivia
                  </span>
                </address>
              </div>
              <div className="col-12 col-md-6">
                <div className="social-links">
                  <ul>
                    <li><a target="_blank" href="https://www.facebook.com/innovasaludbolivia"><i className="fab fa-facebook-f" /></a></li>
                    <li><a target="_blank" href="https://twitter.com/Innovasaludbol1"><i className="fab fa-twitter" /></a></li>
                    <li><a target="_blank" href="https://www.instagram.com/innovasaludbolivia/"><i className="fab fa-instagram" /></a></li>
                    <li><a target="_blank" href="https://www.linkedin.com/company/innovasalud-bolivia"><i className="fab fa-linkedin" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Address */}
        </div>
        );
    }
}
export default HomeEcom;