import React, { Component } from 'react';
import { Link,Redirect } from 'react-router-dom';
 
import {logoutInnova}  from '../functions.js';

class Login extends Component{
	
	
	constructor(props) {
		super(props);
		this.state = { 
			goHome:false
		};
	}
 
  
	componentDidMount=async()=>{	 
		document.body.classList.add('account-page');
		console.log("LOGOUT", resp)
		var resp = await logoutInnova();
		
		if (resp.success){
			this.setState({ ...this.state, goHome: true})
			window.location.reload()
		}
		
	}
	componentWillUnmount(){
		document.body.classList.remove('account-page');
	}
	
    render(){
        return(
        
     		<> 
	        {this.state.goHome ?
						<Redirect
							to={{ pathname: "/" }}
						/>
						: ""
					} 
        </>
        );
    }
}

export default Login;