import React from 'react';
import ReactDOM from 'react-dom';

import AppRouter from './approuter';
// import * as serviceWorker from './client/serviceWorker';
 
import './client/assets/css/bootstrap.min.css';
//fontawesome
import './client/assets/css/fontawesome.min.css'; 
import 'antd/dist/antd.css';
//import 'react-image-lightbox/style.css';

if(!window.location.pathname.includes("admin") || !window.location.pathname.includes("pharmacyadmin")) {
    
    require('./client/assets/css/all.css')
    require('./client/assets/css/all.min.css')
    require('./client/assets/css/fontawesome.min.css')
    require('./client/assets/css/style.css')
}
 
ReactDOM.render(<AppRouter/>, document.getElementById('root'));

if (module.hot) { // enables hot module replacement if plugin is installed
 module.hot.accept();
}