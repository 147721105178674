import React, { Component } from "react";
import { Link } from "react-router-dom";

import { getPlans, getMensajes, decodeToken } from "../../functions.js";
import { MDBIcon } from "mdbreact";
import Loader from "../../Loader";
var fecNum = Date.now();

class BookingSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mensajes_inferiores: [],
      isExecuting: true,
      mensajes_inferiores_zoom: [],
      mensajes_inferiores_whats: [],
      nombre_cliente: "",
      apellido_paterno: "",
      ci_cliente: "",
      fecha: "",
      hora: "",
      nombre_producto: "",
      telefono_cliente: "",
      id_consultorio: "",
      personal_meeting_url: "",
      consultorio: "",
      direccion: "",
      zona: "",
      ciudad: "",
      latitud: "",
      longitud: "",
      token: "",
      messageOrden: "",
    };
  }
  componentDidMount = async () => {
    var productType = this.props.match.params.productType;

    if (productType === "atencion") {
      let _token = this.props.location.search.split("&");

      let token = _token[0].substr(3, _token[0].length);

      let messageOrden = _token[1]
        ? _token[1].replace("messageOrder=", "")
        : "";

      token = token.substr(3, token.length);
      let respDatos = await decodeToken(token);
      let datos = respDatos.datos;

      let respMensajes = await getMensajes();
      let mensajes_inferiores = respMensajes.mensajes_inferiores;
      if (datos.id_consultorio === -1) {
        if (datos.personal_meeting_url) {
          mensajes_inferiores = respMensajes.mensajes_inferiores_zoom;
        } else {
          mensajes_inferiores = respMensajes.mensajes_inferiores_whats;
        }
      }
      this.setState({
        ...this.state,
        mensajes_inferiores: mensajes_inferiores,
        mensaje_alta_reserva: respMensajes.mensaje_alta_reserva,
        mensaje_cambio_reserva: respMensajes.mensaje_cambio_reserva,
        mensaje_cancelacion_reserva: respMensajes.mensaje_cancelacion_reserva,
        mensaje_reserva_existente: respMensajes.mensaje_reserva_existente,
        mensaje_tiempo_cancelacion: respMensajes.mensaje_tiempo_cancelacion,
        mensaje_tiempo_cambio: respMensajes.mensaje_tiempo_cambio,
        mensaje_elija_reverva: respMensajes.mensaje_elija_reverva,
        mensaje_confirmar_fecha: respMensajes.mensaje_confirmar_fecha,
        mensaje_soporte_tecnico: respMensajes.mensaje_soporte_tecnico,
        mensaje_error_compra: respMensajes.mensaje_error_compra,
        nombre_cliente: datos.nombre_cliente,
        apellido_paterno: datos.apellido_paterno,
        ci_cliente: datos.ci_cliente,
        telefono_cliente: datos.telefono_cliente,
        fecha: datos.fecha_reserva,
        hora: datos.hora_reserva,
        nombre_producto: datos.nombre_producto,
        id_consultorio: datos.id_consultorio,
        personal_meeting_url: datos.personal_meeting_url,
        consultorio: datos.consultorio,
        direccion: datos.direccion,
        zona: datos.zona,
        ciudad: datos.ciudad,
        latitud: datos.latitud,
        longitud: datos.longitud,
        isExecuting: false,
        token: token,
        messageOrden: messageOrden,
      });
    } else if (productType === "plan") {
      
      let _token = this.props.location.search.split("&");

      let token = _token[0].substr(3, _token[0].length);

      let messageOrden = _token[1]
        ? _token[1].replace("messageOrder=", "")
        : "";

      let respDatos = await decodeToken(token);
      let datos = respDatos.datos;
      console.log("respDatos",respDatos)
      let productos =[
        {CodServicio:respDatos.datos.CodServicio}
      ];

      

      let respMensajes = await getMensajes();
      let mensajes_inferiores = respMensajes.mensajes_inferiores;
      if (datos.id_consultorio === -1) {
        if (datos.personal_meeting_url) {
          mensajes_inferiores = respMensajes.mensajes_inferiores_zoom;
        } else {
          mensajes_inferiores = respMensajes.mensajes_inferiores_whats;
        }
      }
      this.setState({
        ...this.state,
        mensajes_inferiores: mensajes_inferiores,
        mensaje_alta_reserva: respMensajes.mensaje_alta_reserva,
        mensaje_cambio_reserva: respMensajes.mensaje_cambio_reserva,
        mensaje_cancelacion_reserva: respMensajes.mensaje_cancelacion_reserva,
        mensaje_reserva_existente: respMensajes.mensaje_reserva_existente,
        mensaje_tiempo_cancelacion: respMensajes.mensaje_tiempo_cancelacion,
        mensaje_tiempo_cambio: respMensajes.mensaje_tiempo_cambio,
        mensaje_elija_reverva: respMensajes.mensaje_elija_reverva,
        mensaje_confirmar_fecha: respMensajes.mensaje_confirmar_fecha,
        mensaje_soporte_tecnico: respMensajes.mensaje_soporte_tecnico,
        mensaje_error_compra: respMensajes.mensaje_error_compra,
        nombre_cliente: datos.nombre_cliente,
        apellido_paterno: datos.apellido_paterno,
        ci_cliente: datos.ci_cliente,
        telefono_cliente: datos.telefono_cliente,
        fecha: datos.fecha_reserva,
        hora: datos.hora_reserva,
        nombre_producto: datos.nombre_producto,
        id_consultorio: datos.id_consultorio,
        personal_meeting_url: datos.personal_meeting_url,
        consultorio: datos.consultorio,
        direccion: datos.direccion,
        zona: datos.zona,
        ciudad: datos.ciudad,
        latitud: datos.latitud,
        longitud: datos.longitud,
        isExecuting: false,
        token: token,
        messageOrden: messageOrden,
      });

      /*
      for (var key in productos) {
        if (productos[key].tipo_producto === "plan") {
          let resp = await getPlans(productos[key].id_producto);
          productos[key].nombre_producto = resp.result[0].nombre_plan;
        }
      }*/
     
      this.setState({
        ...this.state,
        productos: productos,
        isExecuting: false,
      });
    }
  };
  render() {
    return (
      <div>
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/home">Inicio</Link>
                    </li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Estado del agendamiento</h2>
              </div>
            </div>
          </div>
        </div>
        <Loader idLoading={this.state.isExecuting} />
        {this.state.mensaje_error_compra ? (
          <div className="content success-page-cont">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="card success-card">
                    <div className="card-body">
                      {this.state.productos ? (
                        <section className="comp-section">
                          <div className="card">
                            <div
                              className="ml-1 mt-1 mr-1 alert alert-danger alert-dismissible fade show"
                              role="alert"
                            >
                              <h4 className="alert-heading">
                                Estimado cliente
                              </h4>
                              <p>
                                <i className="fas fa-exclamation-circle mr-2" />
                                {this.state.mensaje_error_compra}{" "}
                              </p>
                              <p>
                                {"Mensaje de Respuesta: " +
                                  this.state.messageOrden}{" "}
                              </p>
                            </div>
                            <p>
                              <Link
                                className="btn btn-rounded btn-danger"
                                to={
                                  "/patient/checkout/plan/" + this.state.productos[0].CodServicio
                                }
                              >
                                Volver a intentar pago
                              </Link>{" "}
                            </p>
                          </div>
                        </section>
                      ) : (
                        <section className="comp-section">
                          <div className="card">
                            <div className="card-body">
                              <div
                                className="ml-1 mt-1 mr-1 alert alert-danger alert-dismissible fade show"
                                role="alert"
                              >
                                <h4 className="alert-heading">
                                  Estimado cliente,
                                </h4>
                                <p>
                                  <i className="fas fa-exclamation-circle mr-2" />
                                  {this.state.mensaje_error_compra}{" "}
                                </p>
                                <p>
                                  {"Mensaje de Respuesta: " +
                                    this.state.messageOrden}{" "}
                                </p>
                                <p>
                                  <Link
                                    className="btn btn-rounded btn-danger"
                                    to={
                                      "/patient/checkout/atencion/0?t=" +
                                      this.state.token
                                    }
                                  >
                                    Volver a intentar pago
                                  </Link>{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default BookingSuccess;
