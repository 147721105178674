import React, { Component } from 'react';
import {
	Doctor01, Doctor02, Doctor03, Doctor04, Specialities01, Specialities02,
	Specialities03, Specialities04
} from "./img";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { API_CONFIG } from '../../../Config/config-env.js';
import Loader from "../Loader";
import DialogR from "./DialogR";
import ServerIO from "./ServerIO";
import { getPlans, getPlansv2, checkPatient, setReserva, checkPlanPatient, decodeToken, setAtencion, setFinAtencion, getCookie, getHorasLibresByIdConsultorioSingle, getMensajes } from '../functions.js';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import IMG01 from "../../assets/images/doc_image.png";
import { MDBIcon, toast, ToastContainer } from 'mdbreact';
import { io } from 'socket.io-client';
import { useState, useEffect } from 'react';
const socket = io(process.env.REACT_APP_SIO_CONFIG);
//var minutos = 1

// el array de los videos
var vids = []// ["/videos/vid2.mp4", "/videos/vid1.mp4"]
// la longitud del array de los videos
var leng = 0// vids.length;
// un numero utilizado para saber que video se está reproduciendo
var n = 0;
// utilizo el evento "ended" para saber si el video se ha acabado


class HomePlans extends Component {

	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		this.state = {
			isExecuting: false,
			showScreen: true,
			isPreparing: true,
			id_hora_doctor: 0,
			mensajes: [],
			active: 0,
			mensaje_atraso: "",
			custommessage: "",
			carnet_identidad: "", //41176547
			goHome: false,
			mensaje_bienvenida_reserva: "",
			messageInnova: "",
			goRegister: false,
			modalConfirm: false,
			modalConfirm2: false,
			modalR: false,
			paciente_no_encontrado: "",
			mensaje_fuera_horario: "",
			url_calificacion: "",
			Cod_oficina: "",
			horas_libres: [],
			buttonsModalR: [
				{
					buttonAction: this.confirmarHora,
					buttonId: 1,
					buttonClass: "btn btn-success btn-sm",
					buttonName: "Registrar Cita"
				},
				{
					buttonAction: this.cancelHora,
					buttonId: 1,
					buttonClass: "btn btn-secondary btn-sm",
					buttonName: "Cancelar"
				}
			],
		};
	}


	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
		clearInterval(this.state.intervalId2);
	}
	componentDidMount = async () => {

		//this.myRef.current.play();

		socket.on('connect', () => console.log("conectado"));


		let token = getCookie("tokenINNOVA");
		var _resp = await decodeToken(token)

		clearInterval(this.state.intervalId);
		clearInterval(this.state.intervalId2);
		var intervalId2 = setInterval(this.gethorasLibres, 20 * 60 * 1000);
		this.setState({ intervalId2: intervalId2 });

		this.setState({ isExecuting: true });
		if (_resp.resp === "OK") {
			if (_resp.datos.rol === "Consultorio" || _resp.datos.rol === "Doctor" || _resp.datos.rol === "Monitor") {

				await this.gethorasLibres()
				var respMensajes = await getMensajes()
				let mensajes = respMensajes.mensajes_consultorio


				vids = respMensajes.videos;
				leng = respMensajes.videos.length;

				if (_resp.datos.rol === "Monitor") {
					setTimeout(function () {

						this.myRef.current.addEventListener("ended", () => {
							this.myRef.current.setAttribute("src", vids[n % leng]);
							n++;
							let newval = n % leng
						})
					}.bind(this), 1500);
				}


				this.setState({ ...this.state, rol: _resp.datos.rol, Cod_oficina: _resp.datos.Cod_oficina, consultorioInfo: _resp.datos, isExecuting: false, mensajes: mensajes })


				console.log(respMensajes.mensaje_fuera_horario)
				this.setState({
					...this.state,
					mensaje_atraso: respMensajes.mensaje_atraso, minutos: respMensajes.minutos,
					mensaje_fuera_horario: respMensajes.mensaje_fuera_horario
				})

				var intervalId = setInterval(this.refreshActive, respMensajes.minutos * 60 * 1000);
				this.setState({ intervalId: intervalId });

				if (_resp.datos.rol === "Consultorio") {
					this.setState({
						...this.state, showScreen: false,
						url_calificacion: respMensajes.url_calificacion,
						mensaje_bienvenida_reserva: respMensajes.mensaje_bienvenida_reserva,
						mensaje_atraso: respMensajes.mensaje_atraso,
						paciente_no_encontrado: respMensajes.paciente_no_encontrado,
						cita_reservada: respMensajes.cita_reservada,
					})
				}



			} else {
				this.setState({ ...this.state, goHome: true, isExecuting: false })
			}
		} else {
			this.setState({ ...this.state, goHome: true, isExecuting: false })
		}
		this.setState({ ...this.state, isPreparing: false })

	}


	setReserva = async () => {
		this.setState({ isExecuting: true, errorLogin: false });
		let selHora = this.state.selHora
		//console.log("cons", this.state.consultorioInfo	, selHora) 
		let token = getCookie("tokenINNOVA");

		let pos = this.state.horas_libres.findIndex((item) => selHora === item.hora_larga);

		//console.log(this.state.horas_libres[pos])
		let id_hora = this.state.horas_libres[pos].id_hora
		var resp = await setReserva(this.state.carnet_identidad, this.state.nombre, this.state.apellido, this.state.plan, token, this.state.selHora, id_hora, this.state.idPaciente);

		//		console.log("RESP", resp)
		if (resp.success) {

			await this.gethorasLibres()

			socket.emit('chat_solicitud', {
				Cod_oficina: this.state.Cod_oficina,
				Cod_empresa: this.state.consultorioInfo.Cod_empresa,
				usuario: socket.id,
				paciente: this.state.nombre + " " + this.state.apellido,
				hora: selHora,
				cedula: this.state.carnet_identidad
			});

			this.setState({ ...this.state, modalConfirm: false, /*showScreen: true,*/ errorLogin: false, isExecuting: false, carnet_identidad: "" });


			if (this.state.rol === "Consultorio") {


				this.setState({ ...this.state, modalSucc: true });


				setTimeout(function () {
					this.setState({ ...this.state, modalSucc: false });
				}.bind(this), 5000);

			}



		} else {
			this.setState({ ...this.state, errorLogin: true, errorMessage: resp.message, isExecuting: false })
		}
	}

	setResetMensaje = () => {
		let minutos = this.state.minutos
		//console.log("RESET", minutos)
		clearInterval(this.state.intervalId);

		var intervalId = setInterval(this.refreshActive, minutos * 60 * 1000);



		setTimeout(function () {
			this.setState({ active: 0 });
		}.bind(this), 3000);

		this.setState({ intervalId: intervalId });
	}

	refreshActive = () => {

		let _active = this.state.active + 1

		if (_active >= this.state.mensajes.length) {

			this.setState({ active: 0 });
		} else {

			this.setState({ active: _active });
		}
	}


	gethorasLibres = async (cedula) => {

		const Cod_empresa = getCookie("empresaINNOVA");
		let token = getCookie("tokenINNOVA");
		this.setState({ isExecuting: true });
		let horas_libres = []
		let date = new Date();
		let fechaStr = new Date().toLocaleDateString("es-es", { month: "long", day: "numeric", hour: "numeric", minute: "numeric", hour12: true })
		let fechaActual = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "00") + "-" + date.getDate()

		let hora = (date.getHours().toString().padStart(2, "00")) + ":" + (date.getMinutes().toString().padStart(2, "00"))
		var resp2 = await getHorasLibresByIdConsultorioSingle(Cod_empresa, fechaActual, 0, token);

		//console.log(resp2)
		//fechaActual = '2023-11-10'//check!!!!			
		//hora = '10:45'

		//var resp2 = await getHorasLibresByIdConsultorioSingleV2( this.state.consultorioInfo.Cod_empresa, fechaActual, 0, token);
		if (resp2.horas_libres.length > 0) {
			horas_libres = resp2.horas_libres
			let horaAct = parseInt((date.getHours().toString().padStart(2, "00")) + "" + (date.getMinutes().toString().padStart(2, "00")))

			//horaAct = 1045

			let posCurr
			let posNext = -1
			let succNext = false
			//console.log("horaAct",horaAct)
			//console.log("succNext",succNext)
			let firstN = true
			for (let h in horas_libres) {
				let hora = horas_libres[h]
				let _hora1 = parseInt(hora.hora_larga.substr(0, 5).replace(":", ""))
				let _hora2 = horas_libres[h + 1] ? parseInt(horas_libres[h + 1].hora_larga.substr(0, 5).replace(":", "")) : 9999


				if (firstN) {
					if (horaAct < _hora1) {
						hora["now"] = true
						firstN = false
					}
				}


				if (hora.cedula && hora.cedula !== "S/D") {
					//	console.log("!!!!!!!!!!!!!!!!")
					hora["free"] = false
					//	console.log(hora)
					if (hora.atendido === "N") {
						hora["selected"] = true
					} else if (hora.atendido === "E") {
						hora["current"] = true
					} else if (hora.atendido === "S") {
						hora["expired"] = true
					}

				} else {
					//console.log("checking",  horaAct, _hora1 )
					hora["cedula"] = ""
					hora["paciente"] = "libre"
					hora["free"] = true



					let fitrar = false
					if (horaAct < _hora1 && !succNext) {
						//	console.log("cumple 1")
						if (!hora.esReservada) {
							//		console.log("cumple 2")
							posNext = h
							succNext = true

						} else {
							hora["selected"] = true
							fitrar = true
						}

					}
					if (horaAct >= _hora1) {
						posCurr = h
					}

					if (horaAct > _hora1) {
						//console.log("expired")
						hora["free"] = false
						hora["expired"] = true
					} else {
						if (!fitrar) {
							hora["free"] = true
						}
					}


					if (hora.cedula === "S/D") {
						hora["cedula"] = ""
					}

				}
			}

			//	console.log("poss curr", posCurr)
			//	console.log("posNext", posNext)

			if (!posCurr) {
				for (let h in horas_libres) {
					let hora = horas_libres[h]
					if (!posCurr) {

						if (!hora.cedula || hora.cedula === "") {
							posCurr = h
							//horas_libres[h]["current"] = true
							horas_libres[h]["free"] = true
							horas_libres[h]["expired"] = false
						}
					}
					if (posNext < 0) {

						let _hora1 = parseInt(hora.hora_larga.substr(0, 5).replace(":", ""))
						//	console.log("CH", _hora1, horaAct)
						if (!hora.cedula || hora.cedula === "") {
							if (horaAct > _hora1) {
								posNext = h
							}
						}
					}
				}
			}

			if (posNext >= 0) {

				if (cedula) {
					horas_libres[posNext]["cedula"] = cedula
				}

				/*				
								horas_libres[posNext]["next"] = true
								horas_libres[posNext]["free"] = false
								horas_libres[posNext]["expired"] = false
				*/
				this.setState({
					...this.state,
					hora: horas_libres[posNext].hora_larga,
					selHora: horas_libres[posNext].hora_larga,
				})
				console.log("selHora", horas_libres[posNext].hora_larga)
			} else {
				console.log("no hay hora")
				this.setState({ ...this.state, lockReservas: true })
			}


			if (posCurr === 0) {
				//horas_libres[0]["current"] = false
				//horas_libres[0]["free"] = true
			}

			//console.log(horas_libres)
			this.setState({
				...this.state, horas_libres: horas_libres,
				isExecuting: false,
				fechaActual: fechaActual,
				fechaStr: fechaStr,
			})
		} else {
			this.setState({
				...this.state, lockReservas: true
			})
		}

		try {
			this.myRef.current.pause();
		} catch (Ec) {

		}
		setTimeout(function () {
			try {
				this.myRef.current.play();
			} catch (Ec) {
			}
		}.bind(this), 100);

	}

	loginInnova = async () => {


		this.setState({ isExecuting: true });
		var resp = await checkPlanPatient(this.state.carnet_identidad);
		//console.log(resp)
		if (resp.success) {
			let IdPaciente = resp.data.IdPaciente


			await this.gethorasLibres()

			this.setState({
				...this.state, goRegister: true, isExecuting: false,
				IdPaciente: IdPaciente,
				userName: resp.data.Pnombre + " " + resp.data.Papellido + " " + resp.data.Sapellido,
				nombre: resp.data.Pnombre,
				apellido: resp.data.Papellido,
				plan: resp.data.Items[0]?.Nombre,
				consultorio: this.state.consultorioInfo.primer_nombre,
				doctor: this.state.consultorioInfo.segundo_nombre,
				modalConfirm: true,

				//modalR      : true
			})


		} else {
			this.setState({ ...this.state, errorLogin: true, errorMessage: this.state.paciente_no_encontrado, isExecuting: false })
		}
	}


	showMessage = async (mensaje, data) => {
		await this.gethorasLibres()
		console.log("enter", mensaje)
		this.setState({
			mensajeNew: mensaje,
			modalConfirm2: true,
			pacienteDoc: data.paciente,
			cedulaDoc: data.cedula
		});


		setTimeout(function () {
			this.setState({ ...this.state, modalConfirm2: false });
		}.bind(this), 10000);
	}

	sendMessage = async (cedula, paciente, id_hora) => {
		if (this.state.rol === "Doctor") {
			/*
			let token= getCookie("tokenINNOVA");
			var resp = await setAtencion( token, id_hora);
			console.log(resp)
			if (resp.success){
				this.setState({ ...this.state, custommessage :"", reeloadmessage:false });
				setTimeout(function () { 
					this.setState({ ...this.state, reeloadmessage:false, custommessage:"Paciente "+paciente+" ("+cedula +") pase por favor"  });
				}.bind(this), 100);
			}else{
				toast.error("Error registrando paciente en curso", { closeButton: true });
			}
			*/

			let pos = this.state.horas_libres.findIndex((item) => item.atendido === "E");
			if (pos < 0) {
				this.setState({ ...this.state, custommessage: "", reeloadmessage: false, id_hora_doctor: id_hora });
				setTimeout(function () {
					this.setState({ ...this.state, reeloadmessage: false, custommessage: "Paciente " + paciente + " (" + cedula + ") pase por favor" });
				}.bind(this), 100);
			} else {
				toast.error("Debe cerrar la atencion en curso del paciente: " + this.state.horas_libres[pos].paciente, { closeButton: true });
			}

		}
	}

	updateMessage = async () => {
		//console.log("upd hace lo q tiene q hacer")
		let token = getCookie("tokenINNOVA");
		var resp = await setAtencion(token, this.state.id_hora_doctor);
		let id_hora = this.state.id_hora_doctor
		const horas_libres = this.state.horas_libres
		let pos = this.state.horas_libres.findIndex((item) => id_hora === item.id_hora);


		horas_libres[pos]["selected"] = false
		horas_libres[pos]["current"] = true

		if (resp.success) {
			this.setState({ ...this.state, custommessage: "", reeloadmessage: false, id_hora_doctor: 0, horas_libres: horas_libres });
		} else {
			toast.error("Error registrando paciente en curso", { closeButton: true });
		}
	}

	cerrarConsulta = async (id_hora) => {
		let token = getCookie("tokenINNOVA");
		var resp = await setFinAtencion(token, id_hora);


		const horas_libres = this.state.horas_libres
		let pos = this.state.horas_libres.findIndex((item) => id_hora === item.id_hora);


		if (resp.success) {
			horas_libres[pos]["current"] = false
			horas_libres[pos]["expired"] = true
			horas_libres[pos]["atendido"] = "S"
			this.setState({ ...this.state, horas_libres: horas_libres });

			//aqui aviso

			socket.emit('chat_message', {
				Cod_oficina: this.state.Cod_oficina,
				usuario: socket.id,
				mensaje: "Paciente atendido",
				updateTable2: true

			});
			this.setState({
				modalConfirm2: false,

			});




		} else {
			toast.error("Error cerrando consulta, refresque la ventana e intente nuevamente", { closeButton: true });
		}
	}

	clearCustommessage = () => {
		this.setState({ ...this.state, custommessage: "", reeloadmessage: false });
	}
	enviarMensaje2 = async () => {
		//console.log("enviando!")
		let mensaje = this.state.mensajeNew
		let Cod_oficina = this.state.Cod_oficina
		let pacienteDoc = this.state.pacienteDoc
		socket.emit('chat_message', {
			Cod_oficina: Cod_oficina,
			usuario: socket.id,
			mensaje: "El paciente: " + pacienteDoc + " puede pasar"
		});
		this.setState({
			modalConfirm2: false,

		});
	}


	toggleConfirm = () => {
		this.setState({
			modalConfirm: !this.state.modalConfirm
		});
	}

	toggleConfirm2 = () => {
		this.setState({
			modalConfirm2: !this.state.modalConfirm2
		});
	}


	setHora = async (hora) => {
		//console.log("setHora",hora)
		this.setState({ selHora: hora });

	}

	confirmarHora = async (hora) => {
		this.setState({ modalR: false, modalConfirm: true, });

	}

	cancelHora = async (hora) => {
		//console.log("cancelHora")
		this.setState({ modalR: false, showScreen: true });

	}


	changeHandler = async (event) => {
		if (event.target.name === "carnet_identidad") {
			this.setState({ ...this.state, [event.target.name]: (event.target.value).trim() });
		} else {
			this.setState({ ...this.state, [event.target.name]: event.target.value });

		}


		if (event.key === 'Enter' && !this.state.lockReservas) {
			this.loginInnova(event)
		}

	}
	toggleR = (event) => {
		this.setState({
			['modalR']: !this.state['modalR']
		});
	}

	render() {
		return (
			<>
				<div>

					<ToastContainer hideProgressBar={true} newestOnTop={false} position={"bottom-right"} autoClose={3000} />
					{this.state.modalConfirm2 &&
						<MDBModal isOpen={this.state.modalConfirm2} centered toggle={this.toggleConfirm2}>
							<MDBModalHeader>
								<h3>
									Ingresó de nuevo Paciente
								</h3>
							</MDBModalHeader>
							<MDBModalBody>
								<MDBContainer>
									<MDBRow style={{ marginTop: 20 }}>
										<MDBCol md="12" mt="1" mb="1" >
											<div style={{ fontSize: "1.2rem", fontWeight: "700" }}>
												{this.state.mensajeNew}
											</div>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</MDBModalBody>
							<MDBModalFooter>
								{/*
							<button type="button"
								className="btn btn-primary submit-btn"
								onClick={this.enviarMensaje2}>
								<span className="ml-2"> Hacer pasar </span> 
							</button>
						*/}
								<button type="button"
									className="btn btn-secondary submit-btn"
									onClick={this.toggleConfirm2}>
									<span className="ml-2"> Cerrar</span>
								</button>
							</MDBModalFooter>
						</MDBModal>
					}

					{this.state.modalConfirm &&


						<MDBModal isOpen={this.state.modalConfirm} centered toggle={this.toggleConfirm}>

							<MDBModalHeader>
								<h3>
									Confirmaci&oacute;n  de Cita
								</h3>
							</MDBModalHeader>
							<MDBModalBody>
								<MDBContainer>
									<MDBRow style={{ marginTop: 0 }}>
										<MDBCol md="12" mt="1" mb="1" >
											<div style={{ fontSize: "1.5rem", }}>
												Hola {this.state.userName}, desea confirmar su reserva para las:

												<span style={{ fontWeight: "700" }}>
													&nbsp;	{this.state.selHora.substring(0, 5)}
												</span>

											</div>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</MDBModalBody>
							<MDBModalFooter>

								<button type="button"
									className="btn btn-outline-secondary submit-btn"
									onClick={this.toggleConfirm}>Cancelar </button>

								<button type="button"
									className="btn btn-primary submit-btn"
									onClick={this.setReserva.bind(this)}>
									<span className="ml-2"> Confirmar </span>
								</button>
							</MDBModalFooter>
						</MDBModal>
					}


					{this.state.goHome ?
						<Redirect
							to={{ pathname: "login-innova" }}
						/>
						:
						""
					}
					<Loader idLoading={this.state.isExecuting} />
					<DialogR
						icon="fas fa-plus"
						open={this.state.modalR}
						toggle={this.toggleR}
						title="Registrar Cita"
						buttons={this.state.buttonsModalR}
						userName={this.state.userName}
						plan={this.state.plan}
						consultorio={this.state.consultorio}
						doctor={this.state.doctor}
						fechaActual={this.state.fechaActual}
						horas_libres={this.state.horas_libres}
						fechaStr={this.state.fechaStr}
						carnet_identidad={this.state.carnet_identidad}
						hora={this.state.hora}
						selHora={this.state.selHora}
						setHora={this.setHora}

					/>

					<div className="main-wrapper login-body mt-3 mb-3"
						style={{
							display: this.state.isPreparing ? "none" : "block",
							visibility: this.state.isPreparing ? "hidden" : "initial",
							transition: "ease-in all .5s"

						}}>
						<div className="login-wrapper">
							<div className={this.state.rol === "Monitor" ? "Container-x" : "container"} >
								{this.state.showScreen ?

									<div className="row flex-center " style={{
										textAlign: "center",
										border: "1px solid #294980",
										paddingTop: "1rem",
										borderRadius: 12,



									}}>
										<div className={"col-12 col-md-12 col-lg-" + (this.state.showScreen ? "12" : "11") + " col-xl-" + (this.state.showScreen ? "12" : "10")}>


											<div className="row" >
												<div className={" col-lg-" + (this.state.rol === "Doctor" ? "6" : "8") + " col-md-12 col-sm-12"} style={{ textAlign: "justify", minHeight: 450 }}>
													{/*!this.state.isExecuting &&*/}
													<div style={{
														borderRadius: 10,
														border: "1px solid #e6e6e6"
													}}
													>
														{!this.state.reloadV && vids.length > 0 &&
															<video id="video" ref={this.myRef} preload="true" autoplay="true" controls width="100%" height="auto">
																<source src={vids[leng - 1]} type="video/mp4" />
															</video>
														}


														{/*<img 
														style={{													
															borderRadius: 10,
															width:"100%"
														}}
													src="/imagenes_consultios/image1.png"  className="img-fluid" alt="Logo" />*/}
													</div>
													{/***/}
													<div className="mt-1">
														{!this.state.reeloadmessage && this.state.mensaje_atraso !== "" &&
															<ServerIO mensaje_atraso={this.state.mensaje_atraso} clearCustommessage={this.clearCustommessage} updateMessage={this.updateMessage} custommessage={this.state.custommessage} setResetMensaje={this.setResetMensaje} active={this.state.active} mensajes={this.state.mensajes} showMessage={this.showMessage} gethorasLibres={this.gethorasLibres} />
														}
														{/*this.state.rol==="Consultorio" ?
														<h3> {this.state.messageInnova}</h3>
													:
														<div className="">
															<textarea className="form-control" onChange={this.changeHandler} name="custommessage" value={this.state.custommessage}>  </textarea>
															<button 
																style={{position:"absolute",
																		right: 15,
																}}
																onClick={()=>{ this.setState({ ...this.state, showScreen: false}) }}  className="btn btn-primary mt-1" type="button">Enviar Mensaje</button>
														</div>
														*/
														}
													</div>
												</div>
												<div className={"col-lg-" + (this.state.rol === "Doctor" ? "6" : "4") + " col-md-12 col-sm-12"} style={{ textAlign: "center", }}>
													<div className="">
														<div className="row">
															<div className="col-md-12">
																<div className="time-slot-2" style={{ padding: "0 10px 0 0" }}>
																	<ul className="">

																		<li style={{ padding: 0 }}>
																			<div className="row">
																				<div className={"col-12"}>
																					<h3 style={{ marginBottom: "0.25rem" }} >AGENDA MÉDICA</h3>
																				</div>

																			</div>
																		</li>

																		<li >
																			<div className="row time-slot-2-header">

																				<div className={"col-2"}>
																					Hora
																				</div>
																				<div className={"col-3"}>
																					Cédula
																				</div>
																				<div className={this.state.rol === "Doctor" ? "col-5" : "col-6"}>
																					Paciente
																				</div>
																				{this.state.rol === "Doctor" &&
																					<div className={"col-2"}>
																						Acción
																					</div>
																				}

																			</div>
																		</li>
																		{this.state.horas_libres.map((item, idx) => {
																			let total = this.state.horas_libres.length
																			let current = 0
																			let isValid = true
																			if (total > 15) {
																				let curr = 0
																				for (let i in this.state.horas_libres) {
																					if (this.state.horas_libres[i].now) {
																						curr = i
																					}
																				}

																				let diffUp = total - curr
																				let diffDown = parseInt(15) - diffUp

																				if (diffDown < 0) {
																					diffDown = 0
																					diffUp = 14
																				}

																				if (idx + diffDown < parseInt(curr)) {
																					isValid = false
																				}

																				if (idx > parseInt(curr) + diffUp) {
																					isValid = false
																				}
																			}

																			return (isValid &&
																				<li style={{ padding: 0 }}>
																					<div className="row" style={{ borderBottom: "0" }}>

																						<div className={"col-2  cedula"}
																							key={"horaLnk1" + item.key}
																							style={{ padding: 0 }}
																						>
																							<span>{item.hora_larga.substr(0, 5)}  </span>
																						</div>

																						<div onClick={() => { if (item.atendido === "N") { this.sendMessage(item.cedula, item.paciente, item.id_hora) } }} className={"col-3  " +
																							(item.next || item.selected ? " timing-selected" : "") +
																							(item.expired ? "  timing-expire" : "") +
																							(item.free ? " timing-free" : "") +
																							(item.current ? " timing-current" : "")
																						} style={{ padding: 0, cursor: item.atendido === "N" ? "pointer" : "no-drop" }}>
																							{item.cedula ? item.cedula : ""}
																						</div>

																						<div className={this.state.rol === "Doctor" ? "col-5" : "col-6"} style={{ padding: "2px 0 0 4px", textAlign: "left" }}>
																							{item.paciente === "libre" ? "" : item.paciente.substr(0, 24)}
																						</div>
																						{this.state.rol === "Doctor" &&
																							<div className={"col-2"}
																								key={"horaLnk2" + item.key}
																								style={{ padding: 0 }}
																							>
																								{item.atendido === "E" &&
																									<button onClick={() => { this.cerrarConsulta(item.id_hora) }} className="btn btn-sm btn-success" type="button">Finalizar</button>
																								}
																							</div>
																						}


																					</div>

																				</li>
																			)
																		})}
																	</ul>
																</div>


																<div style={{ textAlign: "justify" }}>
																	<div className="row mt-1">
																		<div className="col-1" style={{
																			background: "#3deae8",
																			borderRadius: 5,
																		}}></div>
																		<div className="col-5" style={{ fontSize: "0.85rem" }}>Horas Atendidas</div>

																		<div className="col-1" style={{
																			background: "#f1c40f",
																			borderRadius: 5,
																		}}></div>
																		<div className="col-5" style={{ fontSize: "0.85rem" }}>Cita Agendada</div>

																	</div>
																	<div className="row mt-1">
																		<div className="col-1" style={{
																			background: "#00cc52",
																			borderRadius: 5,
																		}}></div>
																		<div className="col-5" style={{ fontSize: "0.85rem" }}>Atención en Curso</div>

																		<div className="col-1" style={{
																			background: "#ffeb9d",
																			borderRadius: 5,
																		}}></div>
																		<div className="col-5" style={{ fontSize: "0.85rem" }}>Horas disponible</div>

																	</div>

																</div>


															</div>
														</div>
													</div>

												</div>
											</div>

											<div className="row mt-1">
												<div className="mb-1 col-lg-12 col-md-12 col-sm-12" style={{ textAlign: "right", padding: "10px 0" }}>
													<div>

														{this.state.rol === "Consultorio" &&


															<>
																{!this.state.lockReservas ?
																	<h3> {this.state.mensaje_fuera_horario}</h3>
																	:
																	<button onClick={() => { this.setState({ ...this.state, showScreen: false }) }} className="btn btn-primary" type="button">Reservar Cita</button>
																}

															</>

														}
													</div>
												</div>
											</div>

										</div>
									</div>
									:
									<div className="row flex-center " style={{ textAlign: "center" }}>
										<div className="col-12 col-md-12 col-lg-12 col-xl-10">
											<div className="row">
												<div className="col-12 col-md-8 col-lg-8 ">
													<h1 style={{ fontSize: "2.5rem" }} >BIENVENIDO</h1>
												</div>
											</div>
											<div className="row">
												<div className="col-12 col-md-8 col-lg-8 flex-middle">
													<div>
														<div className="form-group" style={{ fontSize: "1.5rem", fontWeight: "600", textAlign: "justify" }}>
															{this.state.mensaje_bienvenida_reserva}
														</div>
														<div className="form-group">
															<input style={{ height: 55, fontSize: "1.25rem" }} onChange={this.changeHandler} onKeyDown={this.changeHandler} value={this.state.carnet_identidad} name="carnet_identidad" className="form-control"
																type="text" placeholder="Carnet de Identidad" />
														</div>
														<div className="form-group">

															{this.state.lockReservas ?
																<h3> {this.state.mensaje_fuera_horario}</h3>
																:
																<button style={{ padding: "0.5rem", fontSize: "2rem" }} onClick={this.loginInnova} className="btn btn-success btn-block" type="button">Reservar Cita</button>
															}


															{/*<button style={{padding: "0.5rem", fontSize: "2rem"}} onClick={this.cancelHora}  className="btn btn-secondary btn-block" type="button">Cancelar</button>
														*/}
															<button style={{ padding: "0.5rem", fontSize: "2rem" }} onClick={() => { window.open(this.state.url_calificacion) }} className="btn btn-info btn-block mt-3" type="button">Calificar su consulta</button>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-4 col-lg-4">
													<img src={IMG01} className="img-fluid" alt="Logo" />
												</div>
											</div>
											{this.state.errorLogin ?
												<div className="row">
													<div className="col-12">
														<div className="alert alert-danger alert-dismissible fade show" role="alert">
															<p><span> {this.state.errorMessage}</span></p>
														</div>
													</div>
												</div>
												: ""}

											{this.state.modalSucc &&
												<div className="row">
													<div className="col-12 col-md-8 col-lg-8">
														<div className="alert alert-success alert-dismissible fade show mt-1" role="alert">
															<h4><span> {this.state.cita_reservada}</span></h4>
														</div>
													</div>
												</div>
											}

										</div>
									</div>
								}

							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default HomePlans;