import React from "react"; 

import { io } from 'socket.io-client';
import { useState, useEffect } from 'react';
const socket = io(process.env.REACT_APP_SIO_CONFIG);
const wWidth = window.innerWidth
const DialogR = props => {
	
	const [isConnected, setIsConnected] = useState(false);
	const [nuevoMensaje, setNuevoMensaje] = useState('');
	const [mensajes, setMensajes] = useState([]);
	const [mensaje, setMensaje] = useState("");
  
	useEffect(() => {
  
	   
	}, []);
   
	

	return (
		<>
			{props.open?
				<>
					<div className="modal-backdrop fade show" onClick={props.toggle?props.toggle:()=>console.log("n") }>

					</div>
					<div  className="modal fade top show" style={{"display": "block", overflowY: 'auto'}} tabIndex="-1" role="dialog" aria-hidden="true">
						<div role="document" className="modal-dialog modal-xl modal-dialog-centered">
							<div className="modal-dialog modal-xl modal-dialog-centered" >
								<div className="modal-content">
									<div className="modal-header">
										
										<h4 className="modal-title"> <i className={props.icon} /> {props.title}</h4>
										{props.toggle?
											<button type="button" id={"1"} onClick={props.toggle} className="close" aria-label="Close"><span aria-hidden="true">x</span></button>
										:""}
									</div>
									<div className="modal-body" style={{ minWidth: wWidth>1000 ? 790:"100%", padding: "10px 30px 0px 30px" }}>
									
										<div className="row mt-2">	
											<div className="mt-4 col-lg-6 col-md-12 col-sm-12" style={{textAlign:"center", minWidth:250 , minHeight: 450}}>
												<div>
													<p style={{marginBottom:0}}> <h5>Bienvenido</h5></p>
													<p className="item-cita"> {props.userName}</p>
													<p style={{marginBottom:0}}> <h5>Plan Comprado</h5></p>
													<p className="item-cita"> {props.plan}</p>
													<p style={{marginBottom:0}}> <h5>Consultorio</h5></p>
													<p className="item-cita"> {props.consultorio}</p>

													<p style={{marginBottom:0}}> <h5>Doctor</h5></p>
													<p className="item-cita"> {props.doctor}</p>
													<p style={{marginBottom:0}}> <h5>Fecha Actual</h5></p>
													<p className="item-cita"> {props.fechaStr}</p>
												 
												</div>

												<div className="legend-reservas">
													<div className="row mt-1">	
														<div className="col-1" style={{
															background: "#3deae8",
															borderRadius: 5
														}}></div>
														<div className="col-8">Horas Atendidas</div>
													</div>
													<div className="row mt-1">	
														<div className="col-1" style={{
															background: "#00cc52",
															borderRadius: 5
														}}></div>
														<div className="col-8">Atención en Curso</div>
													</div>
													<div className="row mt-1">	
														<div className="col-1"style={{
															background: "#f1c40f",
															borderRadius: 5
														}}></div>
														<div className="col-8">Cita Agendada</div>
													</div>
												</div>

											</div>
											<div className="col-lg-6 col-md-12 col-sm-12" style={{textAlign:"center", minWidth:250}}>
												<div className="">
													<div className="row">
														<div className="col-md-12">
															<div className="time-slot-2">
																<ul className="">

																	<li > 
																		<div className="row time-slot-2-header">
																			<div className= { "col-2"}>
																				Nro
																			</div>
																			<div className= { "col-4" }>
																					Hora
																			</div>
																			<div className= { "col-6"}>
																				Cédula
																			</div>
																		</div>																						
																	</li>
																	{props.horas_libres.map((item, idx) => {
																		
																		return(
																		<li > 
																			<div className="row" style={{borderBottom: "1px solid #e9e9e9"}}>
																				<div className= { "col-2"}>
																					{idx+1}
																				</div>
																				<div className= { "col-4  cedula"}
																						key={"horaLnk"+item.key}
																						onClick={() => { 
																														if (item.expired){
																															console.log("no")
																														}else{
																															props.setHora( item.hora_larga)
																														}	
																						}}>
																					<span>{item.hora_larga.substr(0,5)}  </span>
																				</div>
																				
																					<div className= { "col-6  "+ 
																				
																						(item.next || item.selected?"timing-selected":"")+
																						(item.expired? "timing-expire":"")+
																						(item.free? "timing-free":"")+
																						(item.current? "timing-current":"")
																					} 
																					onClick={() => { 
																						if (item.expired){
																							console.log("no")
																						}else{
																							props.setHora(item.hora_larga)
																						}	
																					}}>
																					
																					{item.cedula?item.cedula: "" }
																				</div>
																			</div>
																					
																		</li>
																		)
																	})}																
																</ul>
															</div> 
														</div>
													</div>
												</div> 

											</div>
										</div>
											
									</div>
									<div className="modal-footer justify-content-center text-center">
										{props.selHora!="" ?
											<> 
												{props.buttons.map(item => (
														<button type="submit" onClick={item.buttonAction} key={item.buttonId} className={item.buttonClass}>{item.buttonName} </button>
													))
													}  
								
												{props.cancelButton?
													<button type="submit" onClick={props.toggle?props.toggle:console.log("nodef")} key={"cancel"} className="btn btn-secondary btn-sm">Atras </button>
												:""}
											</>
										:"Lo sentimos no hay horas disponibles"}
									</div>
								</div>
							</div>
						</div>
					</div>        
				</>
			:""}
		</>
	);

	};

export default DialogR;
				 