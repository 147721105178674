import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import loginBanner from '../../assets/images/login-banner.png';
class Register extends Component {

    componentDidMount(){
        document.body.classList.add('account-page');
    }
    componentWillUnmount(){
		document.body.classList.remove('account-page');
	}
    render(){
        return(
            <div className="content">
            <div className="container-fluid">
                
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                            
                  
                        <div className="account-content">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-7 col-lg-6 login-left">
                                <img src={loginBanner} className="img-fluid" alt="Doccure Register" />	
                                </div>
                                <div className="col-md-12 col-lg-6 login-right">
                                    <div className="login-header">
                                        <h3>Registro de Pacientes  </h3>
                                    </div>
                                    
                                  
                                    <form action="/doctor/doctor-dashboard">
                                        <div className="form-group form-focus">
                                            <input type="text" className="form-control floating" id="name" />
                                            <label className="focus-label" htmlFor="name">Nombre</label>
                                        </div>
                                        <div className="form-group form-focus">
                                            <input type="text" className="form-control floating" id="mobile" />
                                            <label className="focus-label" htmlFor="mobile">Numero de Celular</label>
                                        </div>
                                        <div className="form-group form-focus">
                                            <input type="password" className="form-control floating" id="password" />
                                            <label className="focus-label" htmlFor="password">Contrase&ntilde;a</label>
                                        </div>
                                        <div className="form-group form-focus">
                                            <input type="password" className="form-control floating" id="password2" />
                                            <label className="focus-label" htmlFor="password">Repita la Contrase&ntilde;a</label>
                                        </div>
                                        <div className="text-right">
                                            <Link to="/login"className="forgot-link">&iquest;Ya tienes una cuenta?</Link>
                                        </div>
                                        <button className="btn btn-primary btn-block btn-lg login-btn" type="button">Crear Cuenta</button>
                                        <div className="login-or">
                                            <span className="or-line"></span>
                                            
                                        </div>
                                       
                                    </form>
                                 
                                    
                                </div>
                            </div>
                        </div>
                        
                            
                    </div>
                </div>
        
            </div>
        
        </div>		
        )
    }
}

export default Register;