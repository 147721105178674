import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DashboardSidebar } from "./sidebar/sidebar.jsx";
import { Tab, Tabs } from "react-bootstrap";
 
import Loader from "../../Loader";
import Dialog from "../../Dialog";
import {  MDBIcon,toast,ToastContainer } from 'mdbreact';
import {enviarWspV2, enviarMailV2, getMensajes, getEstadoReserva, cancelaReserva, getProductosByCliente,getReservasByCliente, decodeToken,generateTempToken}  from '../../functions.js';
import {API_CONFIG} from '../../../../Config/config-env.js';
import {
  IMG01,
  IMG02,
  IMG03,
  IMG04,
  IMG05,
  IMG06,
  IMG07,
  IMG08,
  IMG09,
  IMG10,
} from "./img";


var fecNum = Date.now();
var fecNum2 = Date.now()+1;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      bookings:[],
      token_to_delete:"",
      loaded:false,
      customerDocumentId:0,
      RadioReservationType:0,   
      customerName:"",
      customerId:0,
      productId:0,
      birthDate:null,
      bookingName:"",
      products:[],
      productsNV:[],
      customerAge:0,
      validityProduct:"",
      productName:"",
      modal1:false,
      hoy:new Date(),
      buttonsModalCancel : [
				{	buttonAction: this.cancelaReserva,
					buttonId    : 1,
					buttonClass : "btn btn-outline-danger btn-sm",
					buttonName  : "Cancelar Reserva"}
			]
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(key) {  
    this.setState({ key });
  }
  onClickRadioReservationType = (nr, serviceId, key ) => () => {
  	this.setState({
         ...this.state,  
			  		RadioReservationType: nr 
   			});
   	const bookings = this.state.bookings	
   	for (var i in bookings){ 
   		if(bookings[i].id_servicio==serviceId){ 
   			bookings[i].llaves[key].serviceType=nr
   		}
   	} 
  };
  
  componentDidMount=async()=>{
  	this.setState({   isExecuting:true });
  	
  	var customerDocumentId =  this.props.match.params.customerDocumentId;
  	var bookings =[]
  	var customerName, customerId, birthDate,bookingName, productId,customerAge,validityProduct,productName
  	var respMensajes = await getMensajes()
  	var respPlanes = await getProductosByCliente(customerDocumentId, true) 
  	var respPlanesNV = await getProductosByCliente(customerDocumentId, false) 
   	var products = respPlanes.productos

   	var productsNV = respPlanesNV.productos
 
  	for (var key in products){
  		 
  		if(key==="0"){
  			let respReservas = await getReservasByCliente(customerDocumentId,products[key].id_producto_cliente)   
  			bookings =  respReservas.reservas
  			customerName = products[key].nombre_cliente
  			customerId = products[key].id_cliente
  			bookingName = products[key].nombre_producto
  			productId = products[key].id_producto_cliente
  			productName = products[key].nombre_producto
  			
				var hoy = new Date();
				var fechaNaci = new Date(products[key].fecha_nacimiento);
				customerAge = hoy.getUTCFullYear() - fechaNaci.getUTCFullYear();
				var m = hoy.getUTCMonth() - fechaNaci.getUTCMonth();
				if (m < 0 || (m === 0 && hoy.getUTCDate() < fechaNaci.getUTCDate())) {
				  customerAge--;
				}
				const MESES = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
				const f = new Date(products[key].fecha_nacimiento);
				birthDate = ((f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() )) +" "+ MESES[f.getUTCMonth()] +" " + f.getUTCFullYear() ; 
				var val = new Date(products[key].fecha_inicio);
				var val_h = new Date(products[key].fecha_inicio);
				val_h.setUTCDate(val_h.getUTCDate() + products[key].dias_vigencia);
				
				validityProduct= "Vigencia del "+ ( (val.getUTCDate() )<10? "0"+(val.getUTCDate()): (val.getUTCDate() )) +" de "+ MESES[val.getUTCMonth()] +" de " + val.getUTCFullYear() +" al " + ( (val_h.getUTCDate() )<10? "0"+(val_h.getUTCDate()): (val_h.getUTCDate() )) +" de "+ MESES[val_h.getUTCMonth()] +" de " + val_h.getUTCFullYear() ; 
  		}
  	}
  	this.setState({ ...this.state,
  								 products:products,
  								 loaded:true,
  								 productId:productId,
                   bookings:bookings,                   
                   customerId:customerId,
                   customerName:customerName,
                   customerDocumentId: customerDocumentId,
                   birthDate:birthDate,
                   customerAge:customerAge,
                   bookingName:bookingName,
                   productName:productName,
                   isExecuting:false,
                   productsNV: productsNV,
                   validityProduct:validityProduct,
                  
                 });	
  	 
	}
	toggleM = nr => () => { 
	  this.setState({
	      ['modal' + nr]: !this.state['modal' + nr]
	  });
  }
  
  
	cancelaReserva=async (event) => {
  	 	 
		var isValid= this.state.token_to_delete.length>0;
 
  	if(isValid){
  		this.setState({ ...this.state,  isExecuting:true });
  		var respToken =await decodeToken(this.state.token_to_delete)
  		var respEstado =  await getEstadoReserva( this.state.token_to_delete, respToken.datos)
 
  		if (respEstado.estado_reserva && respEstado.datosReserva.dif_cancelar_cita >= 0){
  			var respCanc =  await cancelaReserva( this.state.token_to_delete)
        console.log("respCanc",respCanc)
	  		if (respCanc.resp === "NOK"){
	  			toast.error(respCanc.desc, {closeButton: true});
	  			this.setState({ ...this.state,  isExecuting:false,modal1:false });
	  		}else{
          this.setState({ ...this.state,modal1:false });
	  			var respMailCanc =  enviarMailV2( this.state.token_to_delete, "correo_cancelacion_reserva" )
	  					respMailCanc =  enviarWspV2 ( this.state.token_to_delete, "wsp_cancelacion_reserva" )
	  			var event = { target:{id: "0_"+ this.state.productId }}
			    await this.getReservasByCliente (event)
			    toast.success(respCanc.desc, {closeButton: true}); 
	  		}
  		}else{
  			toast.error("El tiempo m\u00E1ximo para realizar la cancelaci\u00F3n ya expir\u00F3", {closeButton: true});
  			this.setState({ ...this.state,  isExecuting:false });
  		}
  		
		 
  	}
  	 
  }
	getReservasByCliente =async (event) => {
	  var productId  = (event.target.id).split("_")[1]
	  
 		this.setState({   isExecuting:true, bookings:[] });
    let respReservas = await getReservasByCliente( this.state.customerDocumentId, productId)
   	let products = this.state.products
   	var prodFound =false 
   	var productName, customerAge, birthDate, validityProduct
   	const MESES = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
   	for (var key in products){
  		if(products[key].id_producto_cliente==productId){
  			prodFound= true;
  			productName = products[key].nombre_producto 
  			
				var hoy = new Date();
				var fechaNaci = new Date(products[key].fecha_nacimiento);
				customerAge = hoy.getUTCFullYear() - fechaNaci.getUTCFullYear();
				var m = hoy.getUTCMonth() - fechaNaci.getUTCMonth();
				if (m < 0 || (m === 0 && hoy.getUTCDate() < fechaNaci.getUTCDate())) {
				  customerAge--;
				}
				const f = new Date(products[key].fecha_nacimiento);
				birthDate = ((f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() )) +" "+ MESES[f.getUTCMonth()] +" " + f.getUTCFullYear() ; 
				var val = new Date(products[key].fecha_inicio);
				var val_h = new Date(products[key].fecha_inicio);
				val_h.setUTCDate(val_h.getUTCDate() + products[key].dias_vigencia);
				validityProduct= "Vigencia del "+ ( (val.getUTCDate() )<10? "0"+(val.getUTCDate()): (val.getUTCDate() )) +" de "+ MESES[val.getUTCMonth()] +" de " + val.getUTCFullYear() +" al " + ( (val_h.getUTCDate() )<10? "0"+(val_h.getUTCDate()): (val_h.getUTCDate() )) +" de "+ MESES[val_h.getUTCMonth()] +" de " + val_h.getUTCFullYear() ; 
  		}
  	}
  	if (!prodFound){
  		let products = this.state.productsNV 
	   	for (var key in products){ 
	  		if(products[key].id_producto_cliente==productId){
	  			prodFound= true;
	  			productName = products[key].nombre_producto 
	  			
					var hoy = new Date();
					var fechaNaci = new Date(products[key].fecha_nacimiento);
					customerAge = hoy.getUTCFullYear() - fechaNaci.getUTCFullYear();
					var m = hoy.getUTCMonth() - fechaNaci.getUTCMonth();
					if (m < 0 || (m === 0 && hoy.getUTCDate() < fechaNaci.getUTCDate())) {
					  customerAge--;
					}
					const f = new Date(products[key].fecha_nacimiento);
					birthDate = ((f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() )) +" "+ MESES[f.getUTCMonth()] +" " + f.getUTCFullYear() ; 
					var val = new Date(products[key].fecha_inicio);
					var val_h = new Date(products[key].fecha_inicio);
					val_h.setUTCDate(val_h.getUTCDate() + products[key].dias_vigencia);
					validityProduct= "Vigencia del "+ ( (val.getUTCDate() )<10? "0"+(val.getUTCDate()): (val.getUTCDate() )) +" de "+ MESES[val.getUTCMonth()] +" de " + val.getUTCFullYear() +" al " + ( (val_h.getUTCDate() )<10? "0"+(val_h.getUTCDate()): (val_h.getUTCDate() )) +" de "+ MESES[val_h.getUTCMonth()] +" de " + val_h.getUTCFullYear() ; 
	  		}
	  	}
  	}
     
    var fecha = new Date(respReservas.reservas[0].llaves[0].fecha_reserva)
   
    this.setState({ ...this.state,
                   bookings : respReservas.reservas,
                   isExecuting:false,
                   productId:productId,
                   productName:productName,
                   validityProduct:validityProduct
                 });	
  }
	
  
  render() {
    return (
    
   
			<div>
		    <div className="breadcrumb-bar">
		   		<div className="container-fluid">
		      	<div className="row align-items-center">
		        	<div className="col-md-12 col-12">
	              <nav aria-label="breadcrumb" className="page-breadcrumb">
	                  <ol className="breadcrumb">
	                      <li className="breadcrumb-item"><Link to="/home">Inicio</Link></li>

	                  </ol>
	              </nav>
		            <h2 className="breadcrumb-title">Reservas de Atenciones M&eacute;dicas</h2>
		          </div>
		        </div>
		    	</div>
				</div>		
				<Loader idLoading={this.state.isExecuting}/>
				<ToastContainer hideProgressBar={true} newestOnTop={false} position={"bottom-right"} autoClose={3000}/>
				<Dialog open={this.state.modal1} 
								toggle={this.toggleM (1)} 
								icon="fas fa-trash"
								title="Cancelar Reserva" 
								cancelButton={true}
								message="&#191;Est&aacute; seguro de cancelar la reserva?" 
								buttons={this.state.buttonsModalCancel}
				/>
				
				 
				        
				{this.state.loaded && this.state.products.length===0?
					<div className="content">
		        <div className="container-fluid">
		       		<div className="row">
		            <div className="col-12">
		            	<div className="alert alert-warning alert-dismissible fade show" role="alert">
		              	<h4 className="alert-heading">Estimado cliente</h4>
		                <p>EL Carnet de Identidad no corresponde a un cliente Titular <b>activo</b>, recuerde que debe introducir el Carnet de Identidad del Titular o tambi&eacute;n puede comprar un servicio haciendo <span><Link to="/HomeEcom" style={{"text-decoration": "underline"}}> click aqui</Link></span></p>
		           		</div>  		           		
		            </div>
		            <div className="row ml-1">
		            	<div className="col-12">
			            	<br/>
			           		<p>
			           			<Link to={"/login"} className="btn btn-rounded cbtn-outline"> <i className="fas fa-undo" /> volver a intentar</Link>
			           		</p>
			           		<br/>
			           		<br/>
		            	</div>  		           		
		            </div>
		        	</div>
		        </div>
		      </div> 
	      :""}
	      
				{this.state.loaded && this.state.products.length===0?"" 
		    :  
		      <div className="content">
		        <div className="container-fluid">
		       		<div className="row">
		            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
		             
		                <DashboardSidebar customerDocumentId={this.state.customerDocumentId}
		                	productId={this.state.productId}
	                   	products={this.state.products}
	                   	productsNV={this.state.productsNV}
	                   	customerId={this.state.customerId}
	                   	customerName={this.state.customerName}
	                 		customerDocumentId={this.state.customerDocumentId}
	                  	birthDate={this.state.birthDate}
	                  	customerAge= {this.state.customerAge}
	                  	getReservasByCliente={this.getReservasByCliente} />
		              
		            </div>
		            <div  id="atenciones" className="col-md-7 col-lg-8 col-xl-9">
		              <div className="card">
		              <div className="card-body pt-0">
		                  <Tabs
		                    className="tab-view"
		                    activeKey={this.state.key}
		                    onSelect={this.handleSelect}
		                    id="controlled-tab-example"
		                  >
		                  {this.state.bookings.length>0?
		                    <Tab  className="nav-item" eventKey={1} title="Servicios">
		                      <div className="card card-table mb-0">
		                        <div className="card-body ml-1 mr-1">
		                          <div className="table-responsive">
		                          
		                          <h3 className="ml-2 mt-4"> {this.state.productName}</h3>
		                          <h4 className="ml-2"> {this.state.validityProduct}</h4>
		                            <table className="table table-hover table-center">
		                              <thead>
		                                <tr  style={{ "backgroundColor": "#42c3cf", "color": "#fff"}} >
		                                	<th style={{"width":"23px","paddingLeft": "0rem 0.25rem 0.25rem 0.5rem" }}>  Item </th> 
		                                	<th style={{"width":"200px" }}>Atenci&oacute;n M&eacute;dica</th> 
		                                	<th style={{"width":"150px" }}>Estado</th>
		                                	<th style={{"width":"600px", "paddingLeft": "0rem" }} >
		                                  	<table className="table table-hover table-center mb-0 tableBooking">
						                              <thead>
						                                <tr>
						                                  <th style={{"width":"180px" }}>Paciente /<br></br>Disponibilidad</th>
						                                 	<th colSpan="2" style={{"width":"118px", }}> Tipo de Atenci&oacute;n</th>
						                                </tr>
						                              </thead>
																				</table>
		                                  </th>
		                                  
		                                </tr>
		                              </thead>
		                              <tbody>		                               
		                             		{this.state.bookings.map(item => (
			                              	<tr style={{"borderBottom": "0.125rem solid rgba(0, 0, 0, 0.05)"}} key={"row"+item.id_servicio}>
			                              		<td> <div style={{"maxWidth": "23px","padding": "0.25rem 0.125rem !important" }}>   {item.key}  </div> </td>
			                              		<td> <div style={{"maxWidth": "200px", "fontWeight":"bold", "fontSize": "14px" }}>  {item.servicio} </div> </td>
			                              		<td> <span className={ item.estado==="DISPONIBLE"?"badge badge-pill bg-success-light": "badge badge-pill bg-danger-light" } >{item.estado}</span> </td>
			                              		<td style={{"padding": "0.25rem 0.125rem"}}>
				                              		
				                              			<table className="table table-hover table-center mb-0 tableBooking">
								                              
								                              <tbody>
								                              	{item.llaves.map(item2 => (
								                              		<tr key={"row"+item.id_servicio+"_"+item2.key}>
								                              			 
									                              				 
									                              			 <td style={{"width":"180px", "maxWidth":"180px"}}> {item2.id_horario? 
									                              			 	<>
									                              			 		{item2.nombre+" "+item2.apellido} <br></br>
									                              			 		{item2.fecha_str} <br></br>
									                              			 		{item2.hora_str} 
									                              			 		{new Date(item2.fecha_reserva) > new Date()? 
									                              			 			<> 
									                              			 				
										                              			 			{item2.checkedPresencial? <> <br></br>Consultorio {item2.consultorio} - {item2.ciudad} <br></br> <a target="_blank" style={{"textDecoration": "underline"}} href={"https://maps.google.com?q="+item2.latitud+","+item2.longitud} className="alert-link"><MDBIcon icon="map-marker-alt" />Abrir Mapa</a> </> :""}
										                              			 			
										                              			 			{item2.checkedVirtual && item2.personal_meeting_url? <> <br></br> <a href={item2.personal_meeting_url} className="alert-link">(Link reunion Zoom)</a></> :""}
										                              			 			{item2.checkedVirtual && !item2.personal_meeting_url? <> <br></br> <b>(Llamada por WhatsApp al numero {item2.telefono})</b> </> :""}
									                              			 			</>
									                              			 			:""  
									                              			 			
									                              			 		}
									                              			 		</> : (item.estado!=="DISPONIBLE"?"(0 Reservas Disponibles)": (item.disponible===1? "(1 Reserva Disponible)":  "(" + item.disponible+ " Reservas Disponibles)")) } </td>
										                              			 <td style={{"width":"120px", "maxWidth":"120px"}}>
													                              		{item.es_virtual==="S"?
																															<p style={{"marginBottom": "0rem", "fontSize":".75rem"}}>
																															  <label>
																																	<input key={"rbtVirual"+item.key+item2.key} defaultChecked={item2.checkedVirtual} type="radio" name={"radio"+item.id_servicio+"_"+item2.key } onClick={this.onClickRadioReservationType("virtual", item.id_servicio, item2.key )} />
																																	<span className="checkmark ml-1">Virtual</span>
																																</label> 
																															</p> 
																															:<span className="checkmark ml-1"></span>
																														}
																														{item.es_presencial==="S"?
																															<p style={{"marginBottom": "0rem", "fontSize":".75rem"}}>
																																<label>
																																	<input key={"rbtPresencial"+item.key+item2.key} defaultChecked={item2.checkedPresencial} type="radio" name={"radio"+item.id_servicio+"_"+item2.key} onClick={this.onClickRadioReservationType("presencial", item.id_servicio, item2.key)} />
																																	<span className="checkmark ml-1">Presencial</span>
																																</label>
																															</p>
																															:<span className="checkmark ml-1"></span>
																														} 
													                              	</td>
													                            	 
									                              			<td className="text-left" >
										                                    <div className="table-action">
										                                    	{item2.atendido==="S"?
										                                    		<button style={{ "Width": "115px"}} disabled={true} type="button" className={"btn btn-secondary btn-sm"} ><i className="fas fa-ban mr-1"></i>Utilizado</button>
										                                    	:new Date(item2.fecha_reserva)<  new Date()?
										                                    		<button style={{ "Width": "115px"}} disabled={true} type="button" className={"btn btn-danger btn-sm"} ><i className="fas fa-ban mr-2"></i>Vencido</button>
										                                    		:
										                                    		<>
										                                    		<Link to={ "/patient/booking/"+item2.serviceType+"/"+item.id_servicio+(item2.itemType==="BUY"?"":"?t="+item2.llave) } className="booking-doc-img ">
																															<button style={{ "Width": "105px"}} disabled={item2.serviceType?false:true} type="button" className={"btn btn-"+ (item2.id_horario?"success":"outline-primary") +" btn-sm"} ><i className={item2.btnIcon+" mr-2"}></i>{item2.btnName}</button>
																														</Link>
																														{item2.id_horario? <button   type="button"  className="btn btn-outline-danger btn-sm"  
			                                                        onClick={() => { 
			                                                            this.setState({
			                                                                ...this.state,
			                                                                token_to_delete: item2.llave,
			                                                                modal1: true 
			                                                            }); 
			                                                        }}> <i className="fas fa-trash mr-1"></i>Cancelar</button>:"" }	
																														</>
										                                    	}
										                                    </div>
										                                  </td>
										                               
								                              		</tr>
								                       		        ))
	                                  						}  
								                          		</tbody>
			                            					</table>
				                              		
																				</td>
			                              	</tr> 		
	                              		 ))
	                                  }  
		                              </tbody>
		                            </table>
		                          </div>
		                        </div>
		                      </div>
		                    </Tab>
		                  :""} 
		                  </Tabs>
		                </div> 
		              </div>
		            </div>
		          </div> 
		        </div>
		      </div>
	      }
    	</div>
    );
  }
}
export default Dashboard;
