import React from 'react';
import { Link } from 'react-router-dom';
import IMG01 from '../../../../assets/images/patients/user.png';
export const DashboardSidebar = props => {

	return(props.products.length===0?<></>:
    	<div className="profile-sidebar">
				<div className="widget-profile pro-widget-content">
					<div className="profile-info-widget">
						<a href="#0" className="booking-doc-img">
							<img src={IMG01} alt="User" />
						</a>
						<div className="profile-det-info">
							<h3>{props.customerName}</h3>
							<div className="patient-details">
								<h5><i className="fas fa-birthday-cake"></i> {props.birthDate}, {props.customerAge} a&ntilde;os</h5>
								<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> La Paz, Bolivia</h5>
							</div>
						</div>
					</div>
				</div>
				<div className="dashboard-widget">
					<nav className="dashboard-menu">
						<ul>
							<li className="ml-3">
									<i className="fas fa-columns mt-3 mb-3"></i>
									<span> <strong> Planes/Atenciones Vigentes </strong> </span>
							</li>
							{props.products.map(item => (
								<li className={props.productId === item.id_producto_cliente?"active":""}>
									<a value="fff" href="#atenciones" onClick={props.getReservasByCliente} id={"item_"+item.id_producto_cliente} key={"item"+item.id_producto_cliente} >
										<i key={"itemi"+item.id_producto_cliente} className="fas fa-columns"></i>
										<span id={"productId_"+item.id_producto_cliente} key={"items"+item.id_producto_cliente} >{item.nombre_producto}</span>
									</a>
								</li>
								))
              }
							
						</ul>
						
						<ul>
							<li className="ml-3">
									<i className="fas fa-columns mt-3 mb-3"></i>
									<span> <strong> Planes/Atenciones Vencidos </strong> </span>
							</li>
							{props.productsNV.map(item => (
								<li className={props.productId === item.id_producto_cliente?"active":""}>
									<a value="fff" href="#atenciones" onClick={props.getReservasByCliente} id={"item_"+item.id_producto_cliente} key={"item"+item.id_producto_cliente} >
										<i key={"itemi"+item.id_producto_cliente} className="fas fa-columns"></i>
										<span id={"productId_"+item.id_producto_cliente} key={"items"+item.id_producto_cliente} >{item.nombre_producto}</span>
									</a>
								</li>
								))
              }
							
						</ul>
						
					</nav>
				</div>
			</div>
		
	);
}
export default DashboardSidebar;