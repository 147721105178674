import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import Payment1 from '../assets/images/payment1.jpg';
import Payment2 from '../assets/images/payment2.jpg';
import Payment3 from '../assets/images/payment3.jpg';
import Payment4 from '../assets/images/payment4.jpg';

import LogoAPS from '../assets/images/LogoOFICIAL-01_APS.jpg';

const Footer = (props) => {
 	const exclusionArray = [
		'/pages/doctor-grid',
		'/pages/doctor-list',
		'/pages/video-call',
		'/pages/voice-call',
		'/pages/chat-doctor',
		'/patient/doctor-list',
		'/patient/doctor-grid'
	]
	if (exclusionArray.indexOf(props.location.pathname) >= 0) {
		return '';
	}
	return (
		 <footer className="footer">
		 {/* Footer Top */}
		 <div className="footer-top">
		   <div className="container-fluid">
			 <div className="row align-items-center">
			   <div className="col-lg-3 col-md-6">
				 {/* Footer Widget */}
				 <div className="footer-widget footer-about">
				   <div className="footer-logo">
					 <img src={logo} alt="logo" />
				   </div>
				   <div className="footer-about-content">
					 <div className="social-icon">
					   <ul>
<li></li>
						 <li>
						   <a href="#" target="_blank"><img src={Payment2} alt="" /></a>
						 </li>
						 <li>
						   <a href="#" target="_blank"><img src={Payment3} alt="" /></a>
						 </li>
						 
					   </ul>
					 </div>
				   </div>
				 </div>
				 {/* /Footer Widget */}
			   </div>
			   <div className="col-lg-3 col-md-6">
				 {/* Footer Widget */}
				 <div className="footer-widget footer-menu">
				   <h2 className="footer-title">Para pacientes.</h2>
				   <ul>
					 
					 <li><Link to="/login">Agendar Cita</Link></li>
					  
				   </ul>
				 </div>
				 {/* /Footer Widget */}
			   </div>
			   <div className="col-lg-3 col-md-6">
				 {/* Footer Widget */}
				  
				 {/* /Footer Widget */}
			   </div>
			   <div className="col-lg-3 col-md-6">
				 {/* Footer Widget */}
				
				 {/* /Footer Widget */}
			   </div>
			 </div>
		   </div>
		 </div>
		 {/* /Footer Top */}
		 {/* Footer Bottom */}
		 <div className="footer-bottom">
		   <div className="container-fluid">
			 {/* Copyright */}
			 <div className="copyright">
			   <div className="row">
					 <div className="col-md-6 col-lg-6">
					   <div className="copyright-text">
						 <p className="mb-0">Copyright © 2021, INNOVASALUD S.A.</p>
						  <small>Powered by DOX IT SRL</small>
					   </div>
					 </div>
					 <div className="col-md-6 col-lg-6">
					   {/* Copyright Menu */}
					   <div className="copyright-menu">
						 <ul className="policy-menu">
						   <li><Link to="/terms">T&eacute;rminos y Condiciones</Link></li>
						   <li><Link to="/privacy-policy">Pol&iacute;ticas</Link></li>
						 </ul>
					   </div>
					   {/* /Copyright Menu */}
					 </div>
			   </div>
			   
			   <div className="row mt-3">
					 <div className="col-2"
					 style={{
					 	 
							"display": "flex",
							alignItems: "center",
							justifyContent: "start"
					 }}
					 >
					   <a href="#" target="_blank"><img width="140px"   src={LogoAPS} alt="" /></a>
					 </div>
					 <div className="col-10"
					 style={{
					 	textAlign: "left",
					 	 
							"display": "flex",
							alignItems: "center",
							justifyContent: "start"
					 }}
					 >
							<span className="message-aps"> ESTE OPERADOR ESTA BAJO LA FISCALIZACI&Oacute;N Y CONTROL DE LA AUTORIDAD DE FISCALIZACI&Oacute;N Y CONTROL DE PENSIONES Y SEGUROS - APS</span>
					 </div>
			   </div>
			   
			 </div>
			 {/* /Copyright */}
		   </div>
		 </div>
		 {/* /Footer Bottom */}
	   </footer>
			
	);
};

export default Footer;