import React from "react"; 
 
const Dialog = props => {


	return (
		<>
			{props.open?
				<>
					<div className="modal-backdrop fade show" onClick={props.toggle?props.toggle:()=>console.log("n") }></div>
						<div  className="modal fade top show" style={{"display": "block"}} tabIndex="-1" role="dialog" aria-hidden="true">
							<div role="document" className="modal-dialog modal-dialog-centered">
								<div className="modal-dialog modal-dialog-centered" >
									<div className="modal-content">
										<div className="modal-header">
											
											<h4 className="modal-title"> <i className={props.icon} /> {props.title}</h4>
											{props.toggle?
												<button type="button" id={"1"} onClick={props.toggle} className="close" aria-label="Close"><span aria-hidden="true">x</span></button>
											:""}
										</div>
										<div className="modal-body">
											 {props.message}
										</div>
										<div className="modal-footer justify-content-center text-center">
											<>
												{props.buttons.map(item => (
													<button type="submit" onClick={item.buttonAction} key={item.buttonId} className={item.buttonClass}>{item.buttonName} </button>
												 ))
		                   	}  
		                   	
		                   	{props.cancelButton?
		                   		<button type="submit" onClick={props.toggle?props.toggle:console.log("nodef")} key={"cancel"} className="btn btn-secondary btn-sm">Atras </button>
		                   	:""}
	                   	</>
										</div>
									</div>
								</div>
							</div>
			      </div>        
				</>
			:""}
		</>
	);

	};

export default Dialog;
				 