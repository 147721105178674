import React, { Component } from 'react';
import { Link,Redirect } from 'react-router-dom';
 
import {loginInnova}  from '../functions.js';

class Login extends Component{
	
	
	constructor(props) {
		super(props);
		this.state = {
			usuario: "",
			password: "",
			redirect:false, 
			errorLogin: false,
			errorMessage: "",
			goHome:false
		};
	}
	changeHandler = async(event) => {
		if (event.target.name ==="usuario"){
			let value = event.target.value.trim().toLowerCase().replaceAll(" ", "")
			this.setState({ ...this.state,redirect: false, [event.target.name]:  value }); 

		}else{
			this.setState({ ...this.state,redirect: false, [event.target.name]:  (event.target.value) }); 	
		}
		

		let value = event.target.value
		if (event.target.name ==="usuario"){
			value = (value.replaceAll(" ", ""))
			value = value.toLowerCase()
		}

		if (event.key === 'Enter') {
			this.loginInnova(event)
		}

	}
	
	loginInnova=async() => {
 		var resp = await loginInnova(this.state.usuario, this.state.password);
 		if (resp.success){
			console.log(resp)
			if (resp.user.valor1==="Consultorio" || resp.user.valor1==="Monitor"){
				this.setState({ ...this.state, goHomeC: true})
			}else if (resp.user.valor1==="Doctor"){
				this.setState({ ...this.state, goHomeD: true})
			}else{
				this.setState({ ...this.state, goHome: true})
			}
 			
 			window.location.reload()
 			
 		}else{
 			this.setState({ ...this.state, errorLogin: true, errorMessage: resp.error })
 		}
 		
  }
  
	componentDidMount(){
		console.log("login")
		document.body.classList.add('account-page');
	}
	componentWillUnmount(){
		document.body.classList.remove('account-page');
	}
	
    render(){
        return(
        
     		<>
        
	        {this.state.goHome &&
				<Redirect to={{ pathname: "/" }}/>
			}

			{this.state.goHomeC &&
				<Redirect to={{ pathname: "/reservation" }}/>
			}

			{this.state.goHomeD &&
				<Redirect to={{ pathname: "/doctor-dashboard" }}/>
			}
				
	         <div>
	           <div className="main-wrapper login-body">
	            <div className="login-wrapper">
	            	<div className="container">
	                	<div className="loginbox">
	                       
				              <div className="row mt-3">
				             
												<div className="col-12 ">
												<h1>Ingreso de usuarios</h1>
												<p className="account-subtitle">Ingreso con su correo y contrase&ntilde;a</p>
												</div>
											</div>	
				              <div className="row">
				              <div className="col-12 col-md-9 col-lg-6">
				              </div>
											<div className="col-12 col-md-9 col-lg-6">
												
												 
												<form action="/admin/">
													<div className="form-group">
														<input onChange={this.changeHandler} onKeyDown={this.changeHandler} value={this.state.usuario} name="usuario" className="form-control" type="text" placeholder="Usuario" />
													</div>
													<div className="form-group">
														<input onChange={this.changeHandler} onKeyDown={this.changeHandler} value={this.state.password} name="password"  className="form-control" type="password" placeholder="Contrase&ntilde;a" />
													</div>
													<div className="form-group">
														<button onClick={this.loginInnova}  className="btn btn-primary btn-block" type="button">Ingresar</button>
													</div>
												</form>
											  
												 
											</div>
	                        </div>
	                        
	                     {this.state.errorLogin?
												 
							       		<div className="row">
							            <div className="col-12">
							            	<div className="alert alert-danger alert-dismissible fade show" role="alert">
							                <p><span> {this.state.errorMessage}</span></p>
							           		</div>  		           		
							            </div> 
									      </div> 
								      :""}
		      
	                    </div>
	                </div>
	            </div>
	        </div>
          </div>
        </>
        );
    }
}

export default Login;