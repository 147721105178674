import React from "react";
import { MDBIcon } from "mdbreact";


const loadMessage = props => {


  return (
     <>
     {props.idLoading?
     	<>
     		<div  className="loadingBG">
					 
				 </div>
     	
				<div  className="loadingBG2">
					 <div  className="loading" >
					 	<MDBIcon icon="circle-notch" spin size="3x" className="text-loading" fixed /><span className="sr-only text-loading">Loading...</span>
					
					 </div>
				 </div></>
			:""}
       </>
  );

};

export default loadMessage;
				 