import React,{ Component } from 'react';
import { Link } from 'react-router-dom';

import {getPlans, getCookie, getMensajes,decodeToken,getClienteByNumIdentif,getPlansv2,getProductosByCliente,getProductosByClienteV2}  from '../../functions.js';
import {MDBIcon } from 'mdbreact'; 
import Loader from "../../Loader";
var fecNum = Date.now();

class BookingSuccess extends Component{
	
	constructor(props) {
		super(props);
	 
		 
		this.state = { 
      mensajes_inferiores:[],
      isExecuting:true,
      mensaje_alta_plan:"",
      mensajes_inferiores_zoom:[],
      mensajes_inferiores_whats:[],
      nombre_cliente:"",
      apellido_paterno:"",
      ci_cliente:"",
      fecha:"",
      hora:"",
      nombre_producto:"",
      telefono_cliente:"",
      id_consultorio:"",
      personal_meeting_url:"",
      consultorio:"",
      direccion:"",
      zona:"",
      ciudad:"",
      latitud:"",
      longitud:"",
      
      
		};
	}
	componentDidMount= async()=>{
		 
		var productType= this.props.match.params.productType;
		var productId= this.props.match.params.productCode;
		//console.log(productType)
		//console.log(productId)
		var respMensajes = await getMensajes()
		var mensajes_inferiores = respMensajes.mensajes_inferiores;

    console.log("ini!!!!!!!!!")
		console.log("productType", productType)
		if (productType==="atencion"){
			var token=this.props.location.search; 
			
			token = token.substr(3,token.length) 
			var respDatos =await decodeToken(token); 
			var datos = respDatos.datos
			
			
			
			if (datos.id_consultorio ===-1){
				if (datos.personal_meeting_url){
					mensajes_inferiores = respMensajes.mensajes_inferiores_zoom
				}else{
					mensajes_inferiores = respMensajes.mensajes_inferiores_whats
				}			
			}
	    	this.setState({ ...this.state, 
    								mensajes_inferiores         : mensajes_inferiores,
								    mensaje_alta_reserva        : respMensajes.mensaje_alta_reserva,
								    mensaje_alta_reserva2       : respMensajes.mensaje_alta_reserva2,
										mensaje_cambio_reserva      : respMensajes.mensaje_cambio_reserva,
										mensaje_cancelacion_reserva : respMensajes.mensaje_cancelacion_reserva,
										mensaje_reserva_existente   : respMensajes.mensaje_reserva_existente,
										mensaje_tiempo_cancelacion  : respMensajes.mensaje_tiempo_cancelacion,
										mensaje_tiempo_cambio       : respMensajes.mensaje_tiempo_cambio,
										mensaje_elija_reverva       : respMensajes.mensaje_elija_reverva,
										mensaje_confirmar_fecha     : respMensajes.mensaje_confirmar_fecha,
										mensaje_soporte_tecnico     : respMensajes.mensaje_soporte_tecnico,
										nombre_cliente : datos.nombre_cliente,
							      apellido_paterno : datos.apellido_paterno,
							      ci_cliente : datos.ci_cliente,
							      telefono_cliente:datos.telefono_cliente,
							      fecha : datos.fecha_reserva,
							      hora : datos.hora_reserva,
							      nombre_producto : datos.nombre_producto,
							      id_consultorio : datos.id_consultorio,
							      personal_meeting_url : datos.personal_meeting_url,
							      consultorio : datos.consultorio,
							      direccion : datos.direccion,
							      zona : datos.zona,
							      ciudad : datos.ciudad,
							      latitud : datos.latitud,
							      longitud : datos.longitud,
							      isExecuting: false
                });
                
		}else if  (productType==="plan"){
			
			
			let tokenAsesor = getCookie("tokenINNOVA")
			let canal=""
			if (tokenAsesor){
				var _resp = await decodeToken (tokenAsesor)
				var userInfo = _resp.datos		
				canal =  userInfo.canal	
			}else{
				canal="portal"
			}
			


			//var respPlan = await getPlansv2(  canal, productId)
			var respPlan = await getPlans(  productId)
			
			console.log("respPlan:",respPlan, canal)
			
			//var productos =  respPlan.result

			var productos = respPlan.result.filter((plan, index, self) =>
				index === self.findIndex((p) => (
				  p.id_plan === plan.id_plan && p.Codigo === plan.Codigo
				))
			  );

			var token=this.props.location.search;
			token = token.substr(3,token.length) 
			var respDatos =await decodeToken(token); 
			var datos = respDatos.datos
			
			var respCliente = await getClienteByNumIdentif(datos.NumIdentificador);
 
			//var respClientePlanes = await getProductosByClienteV2(respCliente.ci_afiliado); 
			var respClientePlanes = await getProductosByCliente(respCliente.ci_afiliado, true); 
			 
			if ( productos.length==0){
				productos.push({
					Codigo  : datos.CodServicio,
					Nombre  : datos.DescServicio,
					Precio  : datos.MontoTotal
				}) 
			}
				 
			productos.map(function (prod) { 
				console.log("Read",prod.Codigo )
				respClientePlanes.productos.map(function (plan) {
				
		 			if ( prod.Codigo == plan.Codigo){
		 				console.log("es ", plan.Codigo, plan.Certificado	)
		 				prod["Certificado"] = plan.Certificado
			 		}
			 		
				});   
			});  
		 
			console.log("productos",productos)
			
			this.setState({ ...this.state, 
    								mensajes_inferiores    : mensajes_inferiores,
								    mensaje_alta_plan      : respMensajes.mensaje_alta_plan,
								    mensaje_soporte_tecnico     : respMensajes.mensaje_soporte_tecnico,
								    productos 						 : productos,
								    isExecuting            : false});
		 
		}
		
		
	}
    render(){
        return(
	<div>
	  <div className="breadcrumb-bar">
	 		<div className="container-fluid">
	    	<div className="row align-items-center">
	      	<div className="col-md-12 col-12">
	          <nav aria-label="breadcrumb" className="page-breadcrumb">
	              <ol className="breadcrumb">
	                  <li className="breadcrumb-item"><Link to="/home">Inicio</Link></li>
	              </ol>
	          </nav>
	          <h2 className="breadcrumb-title">Estado de la compra</h2>
	        </div>
	      </div>
	  	</div>
		</div>		
		<Loader idLoading={this.state.isExecuting}/>
		<div className="content success-page-cont">
			<div className="container-fluid">    
			  <div className="row justify-content-center">
			    <div className="col-12"> 
			      <div className="card success-card">
		          <div className="card-body">
		          	{this.state.productos?
		          		 <section className="comp-section">
					        	<div className="card">
					        	
					        		<div className="card-body">		              
					            	 
					              <div className="row">
					              	<div className="col-12 text-center display-flex items-center" >
								        		<div className="alert alert-success fade show" role="alert">
							              	<h4 className="alert-heading">Estimado cliente</h4>
							                <p >Su compra fue realizada correctamente</p>
							                <p>{this.state.mensaje_alta_plan} </p>
							                <p><Link className="btn btn-rounded btn-primary" to="https://agenda.innovasalud.bo/login"  >Agendar Citas</Link> </p>
							           		</div>  
					           			</div>
					           		</div>
					           		<div className="row">
					           		<div className="col-12" style={{overflow: 'scroll' }}>
					           			<p className="ml-1"> <h4 >Detalle de la compra</h4> </p>
					                <table className="table table-hover table-top mb-0">
		                      <thead>
		                        <tr>
		                        	<th scope="col">Plan</th>
		                          <th scope="col">Descripci&oacute;n</th>
		                          <th scope="col">Costo Bs. </th>
		                          <th scope="col"style={{ textAlign: 'center' }}>Prestaciones</th>
		                          
		                        </tr>
		                      </thead>
		                      <tbody>
		                      	{this.state.productos.map(item => (
			                        <tr key= {"row"+ item.id_producto}>
			                          <td data-title="Plan">
			                            {item.Codigo}
			                          </td>
			                          <td data-title="Descripci&oacute;n">
			                            {item.Nombre}
			                          </td>		
			                          <td data-title="Costo Bs.">
			                            {item.Precio}
			                          </td>		  
			                          <td data-title="Prestaciones">
			                          	{item.Prestaciones?
			                            <table className="table table-hover table-top mb-0">
							                      <thead>
							                        <tr>
							                       
							                          <th>Descripci&oacute;n</th>
							                          <th>Agendable</th>
							                          <th>Cantidad</th>
							                        </tr>
							                      </thead>
							                      <tbody>
							                      	{item.Prestaciones.map(p => (
								                        <tr key= {"row"+ p.Numero}>
								                           
								                          <td>
								                            {p.Nombre}
								                          </td>		
								                          <td data-title="Agendable">
								                            {p.Agendable}
								                          </td>	
								                          <td data-title="Cantidad">
								                            {p.Cantidad}
								                          </td>	 
								                          	                        
								                        </tr> 
							                        ))
							             						}  
							                      </tbody>
																	</table>
								                	:""}        
			                          </td>	
			                           
			                                                
			                        </tr> 
		                        ))
		             						}  
		                      </tbody>
		                    </table>
		                    </div>  
		                    </div>  
		                    <hr/>
					              <ul className="mt-2">
					                {this.state.mensajes_inferiores.map(item => (
					                    <li key={"msj"+item} className="mt-1"> <small>  {item} </small> </li>
					                ))
					                }
					                 <li>
					                  <small>  {this.state.mensaje_soporte_tecnico}</small> 
					                 </li>
					              </ul> 
					              
				             </div>
					        	</div>
					        </section> 
		          	:          
									<section className="comp-section">
					        	<div className="card">
					        		{this.state.id_consultorio===""?"":
					            <div className="card-body">		              
					            	<div className="ml-1 mt-1 mr-1 alert alert-success alert-dismissible fade show" role="alert">
					              	<h4 className="alert-heading">Estimado cliente</h4>
					                <p>{this.state.mensaje_alta_reserva} </p>
					                <p><Link className="btn btn-rounded btn-primary" to="/login"  >Ver mis reservas</Link> </p>
					                <p>{this.state.mensaje_alta_reserva2} </p>
					              </div>
					              <h2 className="comp-title">Detalle de tu atenci&oacute;n m&eacute;dica</h2>
				                <div className="line" />
				         				<br></br>
				         				<h3 className="comp-title text-dark">Datos del Paciente</h3>
				         				<div className="card-text">Nombres: <strong>  {this.state.nombre_cliente} {this.state.apellido_paterno} {this.state.apellido_materno}</strong> </div>
				         				<div className="card-text">Carnet de Identidad: <strong>  {this.state.ci_cliente} </strong> </div>
				                <br></br>
				         				<h3 className="comp-title text-dark">Datos de la atenci&oacute;n m&eacute;dica</h3>
				              	
				              	<div className="card-text"> Fecha: <strong>  {this.state.fecha} {this.state.hora} </strong> </div>
				              	<div className="card-text"> Plan adquirido: <strong>  {this.state.nombre_producto} </strong> </div>
				              	
				              	{this.state.id_consultorio===-1?
				              		<div className="card-text"> Consultorio: <strong>  Consulta Virtual,  {this.state.personal_meeting_url? <>link para la cita: <p> <a href={this.state.personal_meeting_url} className="alert-link">{this.state.personal_meeting_url}</a></p></>:<>Videollamada por WhatsApp (Celular registrado: {this.state.telefono_cliente} )</>} </strong> </div>
				              	:
				              	<>
				              	<div className="card-text"> Consultorio: <strong>  {this.state.consultorio} </strong> </div>
				              	<div className="card-text"> Direcci&oacute;n: <strong>  {this.state.direccion} </strong> </div>
				              	<div className="card-text"> Zona: <strong>  {this.state.zona} </strong> </div>
				              	<div className="card-text"> Ciudad: <strong>  {this.state.ciudad} </strong> <a target="_blank" style={{"textDecoration": "underline"}} href={"https://maps.google.com?q="+this.state.latitud+","+this.state.longitud} className="alert-link"><MDBIcon icon="map-marker-alt" />Abrir Ubicacion en el Mapa</a>  </div>
				              	</>
				              	}
					              <hr/>
					              <ul className="mt-2">
					                {this.state.mensajes_inferiores.map(item => (
					                    <li key={"msj"+item} className="mt-1"> <small>  {item} </small> </li>
					                ))
					                }
					                 <li>
					                  <small>  {this.state.mensaje_soporte_tecnico}</small> 
					                 </li>
					              </ul> 
					            </div>
					          	}
					          </div>
					        </section> 
				        }
		          </div>
			      </div>
			    </div>
			  </div>	    
			</div>
		</div>
	</div>
        )
    }
}

export default BookingSuccess;
