import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { ManageUsersSidebar } from "../adminDashboard/sidebar/sidebar.jsx";
import { Tab, Tabs } from "react-bootstrap";
import DateTimePicker from '../adminDashboard/DateTimePicker'; 
import Table from '../adminDashboard/Table';
import Loader from "../../Loader";
import Dialog from "../../Dialog";
import {  MDBIcon,toast,ToastContainer } from 'mdbreact';
import {getPlansv2, getOrders, setUsers, getUsers,getCookie, enviarWspV2, getValoresXDominio, enviarMailV2, getMensajes, getEstadoReserva, getClienteByCIV2, getProductosByClienteV2, cancelaReserva, getProductosByCliente,getReservasByCliente, decodeToken,generateTempToken, find}  from '../../functions.js';
import {API_CONFIG} from '../../../../Config/config-env.js';

 
import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var fecNum = Date.now();
var fecNum2 = Date.now()+1;

var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 3) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
		key: 1,
		bookings:[],
		action :"create",
		token_to_delete:"",
		date_label:date_label,
		loaded:false,
		id:0,
		invalidForm: false,
		customerDocumentId:0,
		RadioReservationType:0,   
		customerName:"",
		customerId:0,
		redirect:false,
		productId:0,
		birthDate:null,
		bookingName:"",
		lockInputs:false,
		buttonLabel:"Crear Usuario",
		products:[],
		titulo: <> <i className="fas fa-plus"/> Nuevo Usuario </>,
		servicios:[],
		canales:[],
		users:[], 
		segundo_nombre:"",
		canal:"",
		validations:{
			usuario : false,
			
			apellido_paterno  : false,
			apellido_materno  : false,
			primer_nombre  : false,
			//segundo_nombre  : false,
			cedula  : false,
			estado  : false,		 
			valor1 : false,
			valor2 : false,

		},
		tableData: {
				columns : [

					{ dataIndex: "id", title: "Action",
						render: (text, record) => (<div style={{width:33}}>
						<button className="btn btn-sm btn-info" id={record.id} name="view"
						onClick={this.editarUsuario}
						type="submit"><i className="fas fa-edit" /></button>
							
						</div>
						)
					},

					
					{ dataIndex:"usuario"              , title:"Usuario"},
					{ dataIndex:"valor1"               , title:"Rol"},
					{ dataIndex:"valor2"               , title:"Canal"}, 
					{ dataIndex:"apellido_paterno"     , title:"Apellido Paterno"},
					{ dataIndex:"apellido_materno"     , title:"Apellid Materno" },
					{ dataIndex:"primer_nombre"        , title:"Primer Nombre"},
					{ dataIndex:"segundo_nombre"       , title:"Segundo Nombre"},
					{ dataIndex:"cedula"               , title:"Cedula Identidad"},
					{ dataIndex:"desc_estado"          , title:"Estado"},
					{ dataIndex:"fecha_creacion"       , title:"Fecha Creacion"},
					{ dataIndex:"desc_usuario_registro", title:"Usuario Registro"},
					{ dataIndex:"Cod_Empresa"          , title:"Cod_Empresa/DBClinic"},
					{ dataIndex:"Cod_Ciudad"           , title:"Cod_Ciudad"},
					{ dataIndex:"Cod_Oficina"          , title:"Cod_Oficina/SAP"}

				],
			rows: [], 
			},
			
			
		estados:[ 
						{value:""      , text:"TODOS"},
						{value:"PAGADO", text:"PAGADO"},
						{value:"FACTURADO", text:"FACTURADO"},
						{value:"PENDIENTE", text:"PENDIENTE"},
						{value:"ERROR", text:"ERROR"},
						],
		tipo_pago:"",
		tipos_pago :[
								{value:""      , text:"TODOS"},
						{value:"QR", text:"QR"},
							{value:"Efectivo", text:"PAGO EN EFECTIVO"},
							{value:"CYBER", text:"TARJETA DE DEBITO/CREDITO"},
		],
		
		desde: fromDate,
		hasta: new Date(),
		servicio:"",
		productsNV:[],
		customerAge:0,
		validityProduct:"",
		productName:"",
		modal1:false,
		repartidores:[],
		repartidor:"",
		hoy:new Date(),
		usuario :"",
		contrasenia:"",
		apellido_paterno:"",
		apellido_materno:"",
		primer_nombre:"",
		segundo_nombre:"",
		cedula:"",
		estado:"1",
		Cod_Empresa:"",
		Cod_Ciudad:"",
		Cod_Oficina:"",
		valor1 : "Vendedor",
		valor2 : "",
		etiqueta1n: "Primer Nombre",
		etiqueta2n: "Segundo Nombre",
		etiqueta1a: "Primer Apellido",
		etiqueta2a: "Segundo Apellido",
		etiqueta1c: "Cod_Empresa",
		etiqueta2c: "Cod_Oficina",
		buttonsModalCancel : [
				{	buttonAction: this.cancelaReserva,
					buttonId    : 1,
					buttonClass : "btn btn-outline-danger btn-sm",
					buttonName  : "Cancelar Reserva"}
			]
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  editarUsuario=async(event)=>{

	let id = event.target.id
	if (id===""){
		id = event.target.parentNode.id
	}

	let validations=this.state.validations
	let pos = find( this.state.users, id, "id")
	let data = this.state.users[pos]

	
	validations["contrasenia"] =false
	for (let i in Object.keys(data) ){
		let field = Object.keys(data)[i]
		console.log(field, data[field])

		if (field ==="valor1"){
			if (data[field] === "Consultorio"){

				this.setState({
					etiqueta1n: "Nombre Consultorio",
					etiqueta2n: "Dirección Consultorio",
					etiqueta1a: "Nombre Doctor",
					etiqueta2a: "Apellido Doctor",
					etiqueta1c: "Cod_DBClinic",
					etiqueta2c: "Cod_SAP",
				});
			}else if (data[field] === "Monitor"){

				this.setState({
					etiqueta1n: "Nombre Monitor",
					etiqueta2n: "Dirección Monitor",
					etiqueta1a: "Nombre Monitor",
					etiqueta2a: "Apellido Monitor",
					etiqueta1c: "Cod_DBClinic",
					etiqueta2c: "Cod_SAP",
				});
			}else if (data[field] === "Doctor"){
				this.setState({
					etiqueta1n: "Primer Nombre",
					etiqueta2n: "Segundo Nombre",
					etiqueta1a: "Primer Apellido",
					etiqueta2a: "Segundo Apellido",
					etiqueta1c: "Cod_DBClinic",
					etiqueta2c: "Cod_SAP",
				});
			}else{
				this.setState({
					etiqueta1n: "Primer Nombre",
					etiqueta2n: "Segundo Nombre",
					etiqueta1a: "Primer Apellido",
					etiqueta2a: "Segundo Apellido",
					etiqueta1c: "Cod_Empresa",
					etiqueta2c: "Cod_Oficina",
				});
			}
		}
		if(data[field]){
			this.setState({
				[field]:data[field],
			 });
			 
			validations[field] = data[field].length===0
		}else{
			console.log(field+",")
		}
	}
	
	this.setState({
		validations:validations,
		action:"edit",
		buttonLabel:"Editar Usuario",
		lockInputs: true,
		id : id,
		titulo: <> <i className="fas fa-edit"/>Editar Usuario </>,
		invalidForm:false,
	 });
    
  }

  clearData=async(event)=>{

	let validations={}
	let titulo= <> <i className="fas fa-plus"/> Nuevo Usuario </>;
	let valor1= "Vendedor"
	let valor2= this.state.canales[0]?.value
	this.setState({
		validations:validations,
		action:"create",
		buttonLabel:"Crear Usuario",
		lockInputs: false,		
		titulo: titulo,	 

		id : 0,
		usuario :"",
		contrasenia :"",
		apellido_paterno:"",
		apellido_materno:"",
		primer_nombre:"",
		segundo_nombre:"",
		cedula:"",
		estado:"1",
		Cod_Empresa:"",
		Cod_Ciudad:"",
		Cod_Oficina:"",
		valor1 : valor1,
		valor2 : valor2,
		etiqueta1n: "Primer Nombre",
		etiqueta2n: "Segundo Nombre",
		etiqueta1a: "Primer Apellido",
		etiqueta2a: "Segundo Apellido",
		etiqueta1c: "Cod_Empresa",
		etiqueta2c: "Cod_Oficina",
		//invalidForm:true,
	 });
  }

  
  saveData=async(event)=>{	

	//console.log(this.state.action)

	let data = {
				usuario : false,				
				apellido_paterno  : false,
				apellido_materno  : false,
				primer_nombre  : false,
				//segundo_nombre  : false,
				cedula  : false,
				estado  : false,		 
				valor1 : false,
				valor2 : false,

			};
			
	
	let formData={id: this.state.id, username:this.state.userInfo.id, action: this.state.action } 
	
	//console.log(data, Object.keys(data))
	
	var isValid=true;
	for (let i in Object.keys(data) ){
		let field = Object.keys(data)[i]
	//	console.log(field, this.state[field])

		if (this.state[field]==="" && field!== "contrasenia" ){
			
			data[field] = true
			isValid=false
		}else{
			formData[field] = this.state[field]
		}
	}

	if (this.state.action==="create"){
		if (this.state.contrasenia.toString().length<8  ){
			data["contrasenia"] = true
			isValid=false
			toast.error("La contrase\u00F1a debe tener al menos 8 d\u00EDgitos", { closeButton: true });
		}
	}

	if(this.state.etiqueta2n !== "Dirección Monitor" ){
		if (isNaN(this.state.cedula ) ){
			data["cedula"] = true
			isValid=false
			toast.error("Carnet inv\u00E1lido, solo se permiten n\u00FAmeros", { closeButton: true });
		}else{
			if (this.state.cedula.toString().length<5  ){
				data["cedula"] = true
				isValid=false
				toast.error("Carnet inv\u00E1lido, longitud m\u00EDnima 5 d\u00EDgitos", { closeButton: true });
			}
		}
	}else{
		if(this.state.primer_nombre !== "" ){
			isValid=true
			formData["apellido_paterno"]  = "-"
		}else{
			isValid=false
			toast.error("Complete el nombre de Monitor", { closeButton: true });
		}
	}
	

	formData["contrasenia"] = this.state.contrasenia

	

	if (isValid){

		formData["Cod_Ciudad"]  = this.state.Cod_Ciudad
		formData["Cod_Empresa"] = this.state.Cod_Empresa
		formData["Cod_Oficina"] = this.state.Cod_Oficina
		formData["segundo_nombre"] = this.state.segundo_nombre
		 
		this.setState({ ...this.state, 			
			isExecuting: true,
		});

		var respI = await setUsers(formData)
		console.log(respI)

		if (respI.success){
			

			var resp = await getUsers();

			resp.users.map(function (key) {
				let desc_estado = "Activo"
				if( key.estado ===1){
					desc_estado = "Inactivo"
				}
				key["desc_estado"] = desc_estado
			
			});
		
			const tableData = this.state.tableData
			tableData.rows = resp.users?resp.users:[]
	
			this.setState({ ...this.state, 
							isExecuting: false,
							users: resp.users,
						});
			this.clearData()
			toast.success("Datos registrados correctamente", { closeButton: true });
		}else{
			toast.error(respI.messsage, { closeButton: true });
			this.setState({ ...this.state, 
				isExecuting: false,
			});
		}
		//


	}else{
		this.setState({
			validations:data,
			 
		 });
	}

  }

  changeHandler=async(event)=>{

	let value = event.target.value
	let validations= this.state.validations;
	if (event.target.name ==="usuario"){
		value = (value.replaceAll(" ", ""))
		value = value.toLowerCase()
	}
	if (event.target.name ==="valor1"){

		if (event.target.value==="Consultorio"){

			this.setState({
				etiqueta1n: "Nombre Consultorio",
				etiqueta2n: "Dirección Consultorio",
				etiqueta1a: "Nombre Doctor",
				etiqueta2a: "Apellido Doctor",
				etiqueta1c: "Cod_DBClinic",
				etiqueta2c: "Cod_SAP",
			});
		}else if (event.target.value==="Monitor"){

			this.setState({
				etiqueta1n: "Nombre Monitor",
				etiqueta2n: "Dirección Monitor",
				etiqueta1a: "Nombre Monitor",
				etiqueta2a: "Apellido Monitor",
				etiqueta1c: "Cod_DBClinic",
				etiqueta2c: "Cod_SAP",
			});
		}else if (event.target.value==="Doctor"){
			this.setState({
				etiqueta1n: "Primer Nombre",
				etiqueta2n: "Segundo Nombre",
				etiqueta1a: "Primer Apellido",
				etiqueta2a: "Segundo Apellido",
				etiqueta1c: "Cod_DBClinic",
				etiqueta2c: "Cod_SAP",
			});
		}else{
			this.setState({
				etiqueta1n: "Primer Nombre",
				etiqueta2n: "Segundo Nombre",
				etiqueta1a: "Primer Apellido",
				etiqueta2a: "Segundo Apellido",
				etiqueta1c: "Cod_Empresa",
				etiqueta2c: "Cod_Oficina",
			});
		}
	}

	
	validations[event.target.name] = value.length===0

  	this.setState({
          [event.target.name]:value,
		  validations:validations
   	});

	if (
		this.state.usuario !=="" &&
		this.state.apellido_paterno !=="" &&
		this.state.apellido_materno !=="" &&
		this.state.primer_nombre !=="" &&
		//this.state.segundo_nombre !=="" &&
		this.state.cedula !=="" &&
		this.state.estado !=="" 
	){
		console.log("valid")
/*
		if (this.state.action==="create"){
			if(this.state.contrasenia!==""){
				this.setState({
					invalidForm:false
					});
			}
		}else{
			this.setState({
				invalidForm:false
			 });
		}
		*/
	}

  }
  handleSelect(key) {  
    this.setState({ key });
  }
  onClickRadioReservationType = (nr, serviceId, key ) => () => {
  	this.setState({
         ...this.state,  
			  		RadioReservationType: nr 
   			});
   	const bookings = this.state.bookings	
   	for (var i in bookings){ 
   		if(bookings[i].id_servicio===serviceId){ 
   			bookings[i].llaves[key].serviceType=nr
   		}
   	} 
  };
  
  componentDidMount=async()=>{
  	this.setState({   isExecuting:true });
  	
  	let token= getCookie("tokenINNOVA");
	
	let esAdmin =true;
	var _resp
	if (!token){
		esAdmin=false
	}else{
		_resp = await decodeToken (token)
		if(_resp.datos.rol!=="Administrador"){
			console.log("no permitido")
			esAdmin=false
		}
	}
  	
  	if (!esAdmin){
  		this.setState({   redirect:true, isExecuting: false });
  	}else{
  		
  		var userInfo = _resp.datos
  		this.setState({ userInfo:userInfo });
  		var rol = userInfo.rol
			var canal = userInfo.canal
		var canales=[]
	  	var resp = await getUsers();

		resp.users.map(function (key) {
			let desc_estado = "Activo"
			if( key.estado ===1){
				desc_estado = "Inactivo"
			}
			key["desc_estado"] = desc_estado
		
		});
	
		const tableData = this.state.tableData
		tableData.rows = resp.users?resp.users:[]

		var respC = await getValoresXDominio("canales")
		let valor2= ""
		respC.rows.map(function (key, index) {
			canales.push({ text: key.nombre, value: key.nombre })
			if (index===0){
				valor2 = key.nombre 
			}
		});

		console.log(respC, valor2)

	  	this.setState({ ...this.state, 
						loaded:true,
						users: resp.users,
						canales:canales,
						canal:canal,
						//servicios: servicios, 
						//servicio:servicio,
						isExecuting: false,
						valor2:valor2
	                 });
	   }
	}
	toggleM = nr => () => { 
	  this.setState({
	      ['modal' + nr]: !this.state['modal' + nr]
	  });
  }
 

	
  
  render() {
    return (
   		<>
   		{this.state.redirect ?
					<Redirect
						to={{ pathname: "/" }}
					/>
					: ""
		}
   
			<div>
		    <div className="breadcrumb-bar">
		   		<div className="container-fluid">
		      	<div className="row align-items-center">
		        	<div className="col-md-12 col-12">
	              <nav aria-label="breadcrumb" className="page-breadcrumb">
	                  <ol className="breadcrumb">
	                      <li className="breadcrumb-item"><Link to="/home">Inicio</Link></li>

	                  </ol>
	              </nav>
		            <h2 className="breadcrumb-title">Administraci&oacute;n de Usuarios</h2>
		          </div>
		        </div>
		    	</div>
				</div>		
				<Loader idLoading={this.state.isExecuting}/>
				
			 
				        
				 
		      <div className="content">
			  	<ToastContainer hideProgressBar={true} newestOnTop={false} position={"bottom-right"} autoClose={3000} />
		        <div className="container-fluid">
		       		<div className="row">
		          	<div className="col-md-6 col-lg-5 col-xl-4 theiaStickySidebar">
		          		
		          		<div className="row">
									<div className="col-12">
								    	<div className="col-12">
									    	<span className="h3-responsive "> 
									    		 {this.state.titulo}
									    	</span>
				 							</div>
				 						 </div>
				 					</div>
				 					<span className="or-line"/>  
									<div className="row">
										<div className="col-6">
											<div className="form-group mini-margin card-label">
												<label>Usuario</label>
												<input
													name="usuario"
													disabled={this.state.lockInputs}
													className={"form-control " + (this.state.validations.usuario ? "is-invalid" : "")}
													type="text" value={this.state.usuario} onChange={this.changeHandler} />
											</div>
										</div>				 					 
										<div className="col-6">
											<div className="form-group mini-margin card-label">
												<label>Contraseña*</label>
												<input
													name="contrasenia"
													disabled={this.state.lockInputs_}
													className={"form-control " + (this.state.validations.contrasenia ? "is-invalid" : "")}
													type="text" value={this.state.contrasenia} onChange={this.changeHandler} />
											
											</div>
										</div>	
									</div>

									<div className="row">
										<div className="col-6">
											<div className="form-group mini-margin card-label">
												<label> Rol  </label> 
												<select 
													 className={" browser-default custom-select form-control " + (this.state.validations.valor1 ? "is-invalid" : "")}
													 name="valor1" 
													 onChange={this.changeHandler}
													 defaultValue={this.state.valor1}
													 value={this.state.valor1}>
													<option  value="Vendedor">Vendedor</option>
					              				 	<option  value="Supervisor">Supervisor</option>
													<option  value="Consultorio">Consultorio</option>
													<option  value="Monitor">Monitor</option>
													<option  value="Doctor">Doctor</option>
					              		 			<option  value="Administrador">Administrador</option>
					                  			</select> 
											</div>
										</div>				 					 
										<div className="col-6">
											<div className="form-group mini-margin card-label">
												<label>Canal</label>
												<select 
														name="valor2" 
														className={" browser-default custom-select form-control " + (this.state.validations.valor2 ? "is-invalid" : "")}
														onChange={this.changeHandler}
														value={this.state.valor2}
														defaultValue={this.state.valor2}>
					              					{this.state.canales.map(item => (
					              		 				<option key={item.value} value={item.value}>{item.text}</option>
					              		 			))
					                  				} 
					      						</select> 
											</div>
										</div>	
									</div>

									<div className="row">
										<div className="col-12">
											<div className="form-group mini-margin card-label">
												<label>{this.state.etiqueta1n}</label>
												<input
													name="primer_nombre"
													className={"form-control " + (this.state.validations.primer_nombre ? "is-invalid" : "")}
													type="text" value={this.state.primer_nombre} onChange={this.changeHandler} />
											</div>
										</div>		 
									</div>
									{this.state.etiqueta2n!=="Dirección Monitor" &&
									<>
										<div className="row">
											<div className="col-12">
												<div className="form-group mini-margin card-label">
													<label>{this.state.etiqueta2n}</label>
													<input
														name="segundo_nombre"
														className={"form-control " + (this.state.validations.segundo_nombre_ ? "is-invalid" : "")}
														type="text" value={this.state.segundo_nombre} onChange={this.changeHandler} />
												</div>
											</div>		 
										</div>
										<div className="row">
											<div className="col-12">
												<div className="form-group mini-margin card-label">
													<label>{this.state.etiqueta1a}</label>
													<input
														name="apellido_paterno"
														className={"form-control " + (this.state.validations.apellido_paterno ? "is-invalid" : "")}
														type="text" value={this.state.apellido_paterno} onChange={this.changeHandler} />
												</div>
											</div>		 
										</div>
										<div className="row">
											<div className="col-12">
												<div className="form-group mini-margin card-label">
													<label>{this.state.etiqueta2a}</label>
													<input
														name="apellido_materno"
														className={"form-control " + (this.state.validations.apellido_materno ? "is-invalid" : "")}
														type="text" value={this.state.apellido_materno} onChange={this.changeHandler} />
												</div>
											</div>		 
										</div>
									</>
									}

									<div className="row">
										{this.state.etiqueta2n!=="Dirección Monitor" &&
											<div className="col-6">
												<div className="form-group mini-margin card-label">
													<label>C&eacute;dula de Identidad</label>
													<input
														name="cedula"
														className={"form-control " + (this.state.validations.cedula ? "is-invalid" : "")}
														type="text" value={this.state.cedula} onChange={this.changeHandler} />
												</div>
											</div>
										}
										<div className="col-6">
											<div className="form-group mini-margin card-label">
												<label>Estado</label>
												<select  
														name="estado" 
														className={"form-control " + (this.state.validations.estado ? "is-invalid" : "")}
														onChange={this.changeHandler}
														value={this.state.estado}
														defaultValue={this.state.estado}>
					              					
					              		 			<option value={"1"}>Activo</option>
					              		 			<option value={"2"}>Inactivo</option>
					      						</select> 
											
											</div>
										</div>	
									</div>

									<div className="row">
										<div className="col-4">
											<div className="form-group mini-margin card-label">
												<label>{this.state.etiqueta1c}</label>
												<input
													name="Cod_Empresa"
													className={"form-control " + (this.state.validations.Cod_Empresa_ ? "is-invalid" : "")}
													type="text" value={this.state.Cod_Empresa} onChange={this.changeHandler} />
											</div>
										</div>				 					 
										<div className="col-4">
											<div className="form-group mini-margin card-label">
												<label>Cod_Ciudad</label>
												<input
													name="Cod_Ciudad"
													className={"form-control " + (this.state.validations.Cod_Ciudad_ ? "is-invalid" : "")}
													type="text" value={this.state.Cod_Ciudad} onChange={this.changeHandler} />
											</div>
										</div>	
										<div className="col-4">
											<div className="form-group mini-margin card-label">
												<label>{this.state.etiqueta2c}</label>
												<input
													name="Cod_Oficina"
													className={"form-control " + (this.state.validations.Cod_Oficina_ ? "is-invalid" : "")}
													type="text" value={this.state.Cod_Oficina} onChange={this.changeHandler} />
											</div>
										</div>	
									</div>
									 
									<div className="row mt-3 mb-3">
										
									<div className="col-12">
										<span style={{fontSize:14, fontWeight:600}}>*Si deja la contrase&ntilde;a en blanco no se actualizar&aacute; </span>
										</div>
										<div className="col-6">
											
											<div className="form-group mini-margin card-label">
												<button className="btn btn-danger btn-block" name="search"
							         				onClick={this.clearData}
							          				type="submit">Limpiar Datos</button> 
				 					 	 	</div>
				 					 	</div>

				 						<div className="col-6">
											
											<div className="form-group mini-margin card-label">
												<button className="btn btn-primary btn-block" name="search"
							         				onClick={this.saveData}
													disabled={this.state.invalidForm}
							          				type="submit">{this.state.buttonLabel}</button> 
				 					 	 	</div>
				 					 	</div>
									</div>
		            </div>
		            <div  id="atenciones" className="col-md-6 col-lg-7 col-xl-8">
		              <div className="card">
		             	<div className="card-body pt-0">
		             			
							<div id="results" className="col-12 col-lg-12 text-right mb-2 mt-3" style={{padding:0}}>
							
									
								<ExcelFile filename={"export-ordenes."+this.state.date_label} element={         
									<button disabled={ this.state.tableData.rows.length==0 }  type="button" className="btn btn-sm btn-success" name="set" > Exportar</button>
										}>
									<ExcelSheet data={this.state.tableData.rows} name="ordenes">
										<ExcelColumn label="Usuario" value="usuario"/> 
										<ExcelColumn label="Rol" value="valor1"/> 
										<ExcelColumn label="Canal" value="valor2"/> 
										<ExcelColumn label="Apellido Materno" value="apellido_paterno"/> 
										<ExcelColumn label="Apellido Paterno" value="apellido_materno"/> 
										<ExcelColumn label="Primer Nombre" value="primer_nombre"/> 
										<ExcelColumn label="Segundo Nombre" value="segundo_nombre"/> 
										<ExcelColumn label="Cedula Identidad" value="cedula"/> 
										<ExcelColumn label="Estado" value="desc_estado"/> 
										<ExcelColumn label="Fecha Creacion" value="fecha_creacion"/> 
										<ExcelColumn label="Usuario Registro" value="desc_usuario_registro"/> 
										<ExcelColumn label="Cod_Empresa" value="Cod_Empresa/DBClinic"/> 
										<ExcelColumn label="Cod_Ciudad" value="Cod_Ciudad"/> 
										<ExcelColumn label="Cod_Oficina" value="Cod_Oficina/SAP"/> 
										

									</ExcelSheet>
									
									</ExcelFile> 
							</div>	
		             		
		                  <Table tableData={this.state.tableData}    />
		                   
		                </div> 
		              </div>
		            </div>
		          </div> 
		        </div>
		      </div>
	     	</div>	
	  	</>
    );
  }
}
export default ManageUsers;