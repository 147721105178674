import React, { Component } from 'react';
import { Doctor01,Doctor02,Doctor03,Doctor04,Specialities01,Specialities02,
    Specialities03,Specialities04 } from "./img";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {API_CONFIG} from '../../../Config/config-env.js';
import Loader from "../Loader";
import {MDBIcon} from 'mdbreact';
import {getServices,getMensajes, getPlansv2}  from '../functions.js';

class HomeServices extends Component{
	
	constructor(props) {
		super(props);
	  /*this.state.tipo_atencion!="" && this.state.id_atencion!=""?false:true*/ 
		this.state = {
			collapseID: "collapse1",
			formActivePanel1: 1,
 			formActivePanel1Changed: false, 
 			isExecuting:true,
 			services:[],
 			total_services:0,
 			showAllServices:true,
 			tipo_atencion:"",
 			desc_atencion:"Selecccione una atenci\u00F3n",
 			costo:"0.00",
 			es_virtual:"S",
 			es_presencial:"S",
 			id_atencion:0
		};
	}
	onClickRadioReservationType = nr => () => {
      this.setState({
          tipo_atencion: nr
      });
  };
  
	swapFormActive = (a) => (param) => (e) => {
	  this.setState({
	    ['formActivePanel' + a]: param,
	    ['formActivePanel' + a + 'Changed']: true
	  });
	}

	handleNextPrevClick = (a) => (param) => (e) => {
	  this.setState({
	    ['formActivePanel' + a]: param,
	    ['formActivePanel' + a + 'Changed']: true
	  });
	}

	handleSubmission = () => {
	  alert('Form submitted!');
	}

	calculateAutofocus = (a) => {
	  if (this.state['formActivePanel' + a + 'Changed']) {
	    return true
	  }
	}

	componentDidMount=async()=>{
		
		/*
	 
                
		var resp = await getServices(0)
		var services = resp.result;
		*/
		var respMensajes = await getMensajes()
		var resp = await getPlansv2()
		var services=[]
		
		for (var key in resp.result){
			if (resp.result[key].Prestaciones.length== 1){
				 
				services.push({
					prioridad      : key,
					nombre_atencion: resp.result[key].Nombre,
					desc_atencion  : resp.result[key].Descripcion,
					costo          : resp.result[key].Precio,
					es_virtual     : resp.result[key].Tipo==2?'S':'N',
					es_presencial  : resp.result[key].Tipo==1?'S':'N',
					id_atencion    : resp.result[key].Codigo
				})
					
					
			}
			
		}
		
		
		var desc_atencion,costo, es_virtual,es_presencial, id_atencion
		for (var key in services ){
			if(key == 0){
					desc_atencion = services[key].desc_atencion
					costo = services[key].costo 
					es_virtual = services[key].es_virtual
					es_presencial =services[key].es_presencial
					id_atencion = services[key].id_atencion
			}
		}
		
		this.setState({ isExecuting:false, 
										services: services, 
									 	direccion    :respMensajes.direccion,
										ubicacion    :respMensajes.ubicacion,
										total_services: resp.result.length,
										desc_atencion : desc_atencion,
										costo : costo, 
										es_virtual : es_virtual,
										es_presencial : es_presencial,
										id_atencion : id_atencion });
	}
	
	changeHandler = async(event) => {
		
		var field_value=event.target.value
		var field_name=event.target.name	 
		var services = this.state.services 
		var desc_atencion,costo, es_virtual,es_presencial
		for (var key in services ){
			if(services[key].id_atencion == field_value){
					desc_atencion = services[key].desc_atencion
					costo = services[key].costo 
					es_virtual = services[key].es_virtual 
					es_presencial = services[key].es_presencial 
			}
		
		}
		this.setState({ ...this.state,
									 [event.target.name]: event.target.value,
									 desc_atencion : desc_atencion,
									 costo : costo, 
									 es_virtual : es_virtual,
									 tipo_atencion:"",
									 es_presencial : es_presencial }); 
  }
  
    render(){
        return(            
        <div className="main-wrapper">
             {/* Home Banner */}
         
      	<Loader idLoading={this.state.isExecuting}/>
      	
        {/* Planes */}
        {this.state.showAllServices?
        	<section className="specialities">
	          <div className="container">
	            <div className="section-title text-center">
	              <h2>Nuestras Atenciones</h2>
	              <p>Somos especialistas en ...</p>
	            </div>
	            <div className="row justify-content-center"> 
	               <div className="card card-table" style={{"width":"100%"}}>
	                <div className="card-body">
	                  <div className="table-responsive">
	                    <table className="table table-hover table-center mb-0">
	                      <thead>
	                        <tr>
	                          <th scope="col">Atenci&oacute;n</th>
	                          <th scope="col">Descripci&oacute;n</th>
	                          <th scope="col"> Tipo de Atenci&oacute;n</th> 
	                          <th scope="col">Costo Bs. </th>
	                          <th scope="col"></th>
	                        </tr>
	                      </thead>
	                      <tbody>
	                       
		                        <tr>
		                          <td data-title="Atencion"> 
				                         <div className="form-group card-label">
				                         <span>Ver lista desplegable </span>
				                         <br></br>
																		<select id="id_atencion" name="id_atencion" defaultValue={this.state.id_atencion} onChange={this.changeHandler}>
                                  		{this.state.services.map(item => (
                                  		 		<option key={"item"+item.id_atencion} value={item.id_atencion}>{item.nombre_atencion}</option>
                                  		 ))
                                      } 
                          					</select>
																	</div>
		                          </td>
		                          <td >
		                           <b> { this.state.desc_atencion}</b>
		                            
		                          </td>
		                          <td data-title="Tipo de Atenci&oacute;n" > <div style={{"minHeight":"3.5rem"}}>
		                          	{this.state.es_virtual==="S"?
		                          		<label>
																		<p className="ml-2">
																			<input type="radio" name="radioReservationType" checked={this.state.tipo_atencion==="virtual"?true:false} onClick={this.onClickRadioReservationType("virtual")} />
																			<span className="checkmark ml-1">Virtual</span>
																		</p> 
																	</label>
																	:<span className="checkmark ml-1"></span>
																}
																{this.state.es_presencial==="S"?
																	<p className="ml-2">
																		<label>
																			<input type="radio" name="radioReservationType"  checked={this.state.tipo_atencion==="presencial"?true:false} onClick={this.onClickRadioReservationType("presencial")} />
																			<span className="checkmark ml-1">Presencial</span>
																		</label>
																	</p>
																	:<span className="checkmark ml-1"></span>
																} 	 </div>
		                          </td>
		                          <td data-title="Costo Bs.">
		                            <span className="badge badge-pill bg-success-light">
		                               {"Bs."+this.state.costo}
		                            </span>
		                          </td>
		                          <td className="text-right">
		                            <div className="table-action"> 		 
		                                                       	 
		                            	<Link to={"/patient/booking/"+ this.state.tipo_atencion +"/"+this.state.id_atencion} className="booking-doc-img" style={{"marginRight": "0.5rem"}}>
																		<button disabled={this.state.tipo_atencion==""?true:false } type="button" className="btn btn-primary submit-btn "><MDBIcon icon="laptop-medical"/> <span> Reservar</span></button>
																	</Link>
		                            </div>
		                          </td>
		                        </tr> 
	                         
	                      </tbody>
	                    </table>
	                  </div>
	                </div>
	              </div>
	            </div>
	            
	          </div>
	        </section>
        :
	        "" 
        }
        
        
        <section className="address-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <address className="m-0 d-flex align-items-center">
                  <span className="d-flex align-items-center justify-content-center map-icon">
                   <a target="_blank" href={this.state.ubicacion}>
                   	<i className="fas fa-map-marker-alt" />
                   </a>	
                  </span>
                  <span>
                    {this.state.direccion} <br /> La Paz Bolivia
                  </span>
                </address>
              </div>
              <div className="col-12 col-md-6">
                <div className="social-links">
                  <ul>
                    <li><a target="_blank" href="https://www.facebook.com/innovasaludbolivia"><i className="fab fa-facebook-f" /></a></li>
                    <li><a target="_blank" href="https://twitter.com/Innovasaludbol1"><i className="fab fa-twitter" /></a></li>
                    <li><a target="_blank" href="https://www.instagram.com/innovasaludbolivia/"><i className="fab fa-instagram" /></a></li>
                    <li><a target="_blank" href="https://www.linkedin.com/company/innovasalud-bolivia"><i className="fab fa-linkedin" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Address */}
        </div>
        );
    }
}
export default HomeServices;