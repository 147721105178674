import React, { Component } from 'react';
import loginBanner from '../../assets/images/login-banner.png';
//icon
import {handleKeyPress}  from '../functions.js';


import { Link,Redirect } from 'react-router-dom';
class LoginContainer extends Component {  
	
	constructor(props) {
		super(props);
		this.state = {
			ci_cliente: "",
			redirect:false
		};
	}
	changeHandler = async(event) => {
		this.setState({ ...this.state,redirect: false, [event.target.name]:  (event.target.value).trim() }); 
	}
	
	redirectToLogin = (event) => {		
	
		if(event.key === 'Enter'){
			if(this.state.ci_cliente.length<7){
				//console.log("mmal")
			}
			this.setState({
      	redirect: true
    	})	     
	  }		
	}
	
	redirectToLoginButton = () => {		
		
		if(this.state.ci_cliente.length<7){
				//console.log("mal")
			}
			
		this.setState({
     	redirect: true
    })	
	}
	
	setRedirect = () => {
    this.setState({
      redirect: true
    })
  }
		
	renderRedirect = () => {
    if (this.state.redirect && this.state.ci_cliente.length>=5) {
      return <Redirect to={this.state.ci_cliente!=""?"/patient/dashboard/"+this.state.ci_cliente:"/login"} />
    }
    
  }
	
	
	componentDidMount(){
		document.body.classList.add('account-page');
	}
	componentWillUnmount(){
		document.body.classList.remove('account-page');
	}
 render(){
	return(
		
			<div className="content">
				<div className="container-fluid">
					
					<div className="row">
						<div className="col-md-8 offset-md-2">
							
							
							<div className="account-content">
								<div className="row align-items-center justify-content-center">
									<div className="col-md-7 col-lg-6 login-left">
										<img src={loginBanner} className="img-fluid" alt="INNOVA SALUD" />	
									</div>
									<div className="col-md-12 col-lg-6 login-right">
										<div className="login-header">
											<h3>Revisar mis Servicios</h3>
										</div>
									 
											<div className="form-group form-focus">
											<input onKeyPress={this.redirectToLogin}  onChange={this.changeHandler} value={this.state.ci_cliente} name="ci_cliente" type="text" className="form-control floating" />
												<label className="focus-label">Carnet de Identidad del Titular</label>
											</div>
											 	{this.renderRedirect()}
										
												<button onClick={this.redirectToLoginButton} type="button" className="btn btn-primary btn-block" >Ingresar</button>
										
											 
										 
											<div className="login-or">
												<span className="or-line"></span>
												<span className="span-or"></span>
											</div>
											 
										
										 
								
									</div>
								</div>
							</div>
							
								
						</div>
					</div>

				</div>

			</div>

	);
  }

}

export default LoginContainer;