import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { io } from 'socket.io-client';
import { useState, useEffect } from 'react';
import { getPlans, getPlansv2, checkPatient, setReserva, checkPlanPatient, decodeToken, cargarSonido, getCookie } from '../functions.js';
const socket = io(process.env.REACT_APP_SIO_CONFIG);

function ServerIO(props) {

	const [isConnected, setIsConnected] = useState(false);
	const [modalConfirm, setMdalConfirm] = useState(true);

	const [nuevoMensaje, setNuevoMensaje] = useState('');
	const [enviado, setEnviado] = useState('');

	const [mensaje, setMensaje] = useState("");
	const [minutos, setMinutos] = useState("");



	const [paciente, setPaciente] = useState("");
	const [hora, setHora] = useState("");

	const rol = getCookie("rolINNOVA");
	const Cod_oficina = getCookie("oficinaINNOVA");
	const Cod_empresa = getCookie("empresaINNOVA");

	useEffect(() => {

		if (props.custommessage !== "") {

			setNuevoMensaje(props.custommessage)
		}

		socket.on('connect', () => setIsConnected(true));

		socket.on('chat_message', (data) => {
			//console.log("mensaje recibido ", data, data.Cod_oficina , Cod_oficina, props.minutos*60*1000)
			if (data.Cod_oficina === Cod_oficina) {
				setMensaje(data.mensaje);
				if (data.updateTable) {
					if (rol === "Monitor") {
						console.log("summmm")
						const sonido = cargarSonido("/sound2.mp3");
						sonido.play();
					}
					props.gethorasLibres()

				}

				if (data.updateTable2) {
					props.gethorasLibres()
				}


			} else {

			}
			props.setResetMensaje()

			setTimeout(function () {
				setMensaje("")
			}.bind(this), 1 * 60 * 1000);


		});

		socket.on('chat_solicitud', (data) => {

			if (data.Cod_oficina === Cod_oficina) {
				if (rol === "Doctor") {

					props.showMessage("Ingreso un nuevo paciente: " + data.paciente + "(" + data.cedula + ") para la hora " + data.hora, data)
				} else if (rol === "Monitor") {

					props.gethorasLibres()

				}

			} else {

			}
		});


		return () => {
			socket.off('connect');
			socket.off('chat_message');
			socket.off('chat_solicitud');
		}



	}, [props]);



	const enviarMensaje = async () => {

		if (nuevoMensaje.length > 0) {
			socket.emit('chat_message', {
				Cod_oficina: Cod_oficina,
				Cod_empresa: Cod_empresa,
				usuario: socket.id,
				mensaje: nuevoMensaje,
				updateTable: props.custommessage !== "" ? true : false
			});

			setEnviado("Mensaje enviado")
			if (props.custommessage !== "") {

				await props.updateMessage()
				setNuevoMensaje("")
			}
			setNuevoMensaje("")
			setMinutos("")
			setTimeout(function () {
				setEnviado("")
			}.bind(this), 1250);
		}
	}



	const toggleConfirm = () => {
		setMdalConfirm(!modalConfirm)
	}


	return (
		<div className="App">

			{rol === "Monitor" &&

				<div style={{
					padding: "1rem 13px",
					borderRadius: 10,
					border: "1px solid #e6e6e6",
					minHeight: "5rem"
				}}>


					{mensaje !== "" ?
						<h3> {mensaje}</h3>
						:
						<>

							{props.mensajes.map((item, i) => (props.active === i ?
								<h3> {item}</h3>
								: ""
							))
							}
						</>
					}

				</div>
			}
			{rol === "Doctor" &&
				<>
					<div className="row">
						<div className={"col-12"}>
							<textarea className="form-control" onChange={(e) => {
								setNuevoMensaje(e.target.value);
								props.clearCustommessage();
								setEnviado("")
							}}

								onKeyUp={(e) => {

									if (e.key === 'Enter') {
										enviarMensaje()
									}


								}
								}
								name="custommessage" value={nuevoMensaje}>  </textarea>

						</div>
					</div>

					<div className="row mt-2" >
						<div className={"col-6"}>

							<input
								onChange={(e) => {
									if (!isNaN(e.target.value)) {
										console.log(props.mensaje_atraso)
										let mensaje = props.mensaje_atraso
										mensaje = mensaje.replace("<minutos>", e.target.value)
										//setNuevoMensaje("Estamos atrasados "+e.target.value+ " minutos, disculpe las molestias");  
										setNuevoMensaje(mensaje);
										setMinutos(e.target.value);
										props.clearCustommessage();
									}
								}}
								onKeyUp={(e) => {

									if (e.key === 'Enter') {
										enviarMensaje()
									}


								}
								}

								value={minutos} name="carnet_identidad" className="form-control"
								type="text" placeholder="Tiempo de Atraso" />
						</div>
						<div className={"col-6 flex-right"}>
							<button

								onClick={enviarMensaje} className="btn btn-primary mt-1" type="button">Enviar Mensaje</button>
						</div>
					</div>
					<span className="mensaje-enviado"> {enviado}</span>


				</>
			}
		</div>
	);
}

export default ServerIO;