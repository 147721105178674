import React,{ Component } from 'react';
import { Link } from 'react-router-dom';

import {getPlans, getMensajes,decodeToken}  from '../../functions.js';
import {MDBIcon } from 'mdbreact'; 
import Loader from "../../Loader";
var fecNum = Date.now();

class BookingSuccess extends Component{
	
	constructor(props) {
		super(props);
	 
		 
		this.state = { 
      mensajes_inferiores:[],
      isExecuting:true,
      mensajes_inferiores_zoom:[],
      mensajes_inferiores_whats:[],
      nombre_cliente:"",
      apellido_paterno:"",
      ci_cliente:"",
      fecha:"",
      hora:"",
      nombre_producto:"",
      telefono_cliente:"",
      id_consultorio:"",
      personal_meeting_url:"",
      consultorio:"",
      direccion:"",
      zona:"",
      ciudad:"",
      latitud:"",
      longitud:"",
      token:"",
      messageOrden:""
		};
	}
	componentDidMount= async()=>{
		 
		var productType= this.props.match.params.productType;
		
		if (productType==="atencion"){
			var _token=(this.props.location.search).split("&");
			
			var token=this.props.location.search;			
			token = token.substr(3,token.length) 
			
			var respDatos =await decodeToken(token); 
			var datos = respDatos.datos
			
			var respMensajes = await getMensajes()
			var mensajes_inferiores = respMensajes.mensajes_inferiores;
			if (datos.id_consultorio ===-1){
				if (datos.personal_meeting_url){
					mensajes_inferiores = respMensajes.mensajes_inferiores_zoom
				}else{
					mensajes_inferiores = respMensajes.mensajes_inferiores_whats
				}			
			}
	    this.setState({ ...this.state, 
    								mensajes_inferiores : mensajes_inferiores,
										mensaje_timeout_compra        :respMensajes.mensaje_timeout_compra,
										nombre_cliente : datos.nombre_cliente,
							      apellido_paterno : datos.apellido_paterno,
							      ci_cliente : datos.ci_cliente,
							      telefono_cliente:datos.telefono_cliente,
							      fecha : datos.fecha_reserva,
							      hora : datos.hora_reserva,
							      nombre_producto : datos.nombre_producto,
							      id_consultorio : datos.id_consultorio,
							      personal_meeting_url : datos.personal_meeting_url,
							      consultorio : datos.consultorio,
							      direccion : datos.direccion,
							      zona : datos.zona,
							      ciudad : datos.ciudad,
							      latitud : datos.latitud,
							      longitud : datos.longitud,
							      isExecuting: false,
							      token      :token
                });
                
		}else if  (productType==="plan"){
			var token=this.props.location.search;
			token = token.substr(3,token.length) 
			var respDatos =await decodeToken(token); 
			var datos = respDatos.datos
			var productos = datos.productos
			for (var key in productos){
				if (productos[key].tipo_producto ==="plan"){
					let resp = await getPlans(productos[key].id_producto)	
					productos[key].nombre_producto = resp.result[0].nombre_plan
				}
			}
			this.setState({ ...this.state, productos : productos,isExecuting: false});
		}
		
		
	}
    render(){
        return(
	<div>
	  <div className="breadcrumb-bar">
	 		<div className="container-fluid">
	    	<div className="row align-items-center">
	      	<div className="col-md-12 col-12">
	          <nav aria-label="breadcrumb" className="page-breadcrumb">
	              <ol className="breadcrumb">
	                  <li className="breadcrumb-item"><Link to="/home">Inicio</Link></li>
	              </ol>
	          </nav>
	          <h2 className="breadcrumb-title"> Estado de la compra</h2>
	        </div>
	      </div>
	  	</div>
		</div>		
		<Loader idLoading={this.state.isExecuting}/>
		{this.state.mensaje_timeout_compra?
			<div className="content success-page-cont">
				<div className="container-fluid">    
				  <div className="row justify-content-center">
				    <div className="col-12"> 
				      <div className="card success-card">
			          <div className="card-body">
			          	{this.state.productos?
			          		 <section className="comp-section">
						        	<div className="card">
						        		<div className="ml-1 mt-1 mr-1 alert alert-danger alert-dismissible fade show" role="alert">
					              	<h4 className="alert-heading">Estimado cliente</h4>
					                <p><i className="fas fa-exclamation-circle mr-2" />{this.state.mensaje_timeout_compra} </p>
					           		</div>  
					           			<p><Link className="btn btn-rounded btn-danger" to="/HomeServices">Volver a revervar</Link> </p>
						        	</div>
						        </section> 
			          	:          
										<section className="comp-section">
						        	<div className="card"> 
						            <div className="card-body">		              
						            	<div className="ml-1 mt-1 mr-1 alert alert-danger alert-dismissible fade show" role="alert">
						              	<h4 className="alert-heading">Estimado cliente</h4>
						                <p><i className="fas fa-exclamation-circle mr-2" /> {this.state.mensaje_timeout_compra} </p>
						              	<p><Link className="btn btn-rounded btn-danger" to="/HomeServices">Volver a revervar</Link> </p>
						              </div>
						            </div> 
						          </div>
						        </section> 
					        }
			          </div>
				      </div>
				    </div>
				  </div>	    
				</div>
			</div>
		:""}
	</div>
        )
    }
}

export default BookingSuccess;